<template>
  <transition-group class="ilShopArticleList" name="list" tag="div" :appear="true">
    <il-shop-article-list-entry
      v-for="article in value"
      :key="article.id"
      :value="article"
      :compact="compact"
      :disabled="disabled"
    >
      <slot :article="article" name="article-action"></slot>
    </il-shop-article-list-entry>
  </transition-group>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import ArticleModel from '../../services/v2/model/article-model';
  import IlShopArticleListEntry from './il-shop-article-list-entry.vue';

  export default Vue.extend({
    name: 'IlShopArticleList',
    components: {
      IlShopArticleListEntry,
    },
    props: {
      value: { default: undefined, type: Array as PropType<ArticleModel[]> },
      disabled: { default: undefined, type: Boolean },
      compact: { default: undefined, type: Boolean },
    },
    data: () => ({

    }),
  });
</script>

<style scoped lang="scss">
  .list-enter-active {
    transition: all 0.4s;
    transition-delay: 100ms * 20;
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: 100ms * $i !important;
      }
    }
  }
  .list-leave-active {
    transition: all 0.33s;
    transition-delay: 20ms * 20;
    @for $i from 1 through 10 {
      &:nth-child(#{$i}){
        transition-delay: 20ms * $i !important;
      }
    }
  }
  .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(40px);
  }
</style>

import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import type { WidgetConfig } from '../../../../../components/il-widget-tester.vue.model';

export default class WidgetShopArticleDetailTestingPage extends PageBase {
  get widgetConfig() {
    return {
      route: { value: '/_widget/shop/article-detail', hidden: true },
      'shop-articleid': { label: 'Article id', required: true },
    } as WidgetConfig;
  }

  async initialize(): Promise<void> {
    // Initialize the page
  }
}

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ArticleSchema from './article-schema';

const schema = {
  $id: '/article-variant-schema',
  type: 'object',
  required: [],
  nullable: true,
  properties: {
    title: {
      type: 'string',
      nullable: true,
    },
    article: {
      ...ArticleSchema,
    },
  },
} as IJSONSchema;

export default schema;

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UserAccountModel from '../../model/user-account-model';
import UserAccountDTO from '../dto/user-account-dto';
import dtoSchema from '../schemas/user-account-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import AddressModel from '../../model/address-model';

export default abstract class UserAccountModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UserAccountDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.username = data.username ?? undefined;
      this.addresses = data.addresses?.map((i) => AddressModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  username?: string;
  /**
  */
  addresses?: AddressModel[];

  static toModel(dto: DeepPartial<UserAccountDTO>): UserAccountModel;
  static toModel(dto: DeepPartial<UserAccountDTO> | undefined | null): UserAccountModel | undefined;
  static toModel(dto: DeepPartial<UserAccountDTO> | undefined | null): UserAccountModel | undefined {
    return dto ? new UserAccountModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UserAccountModel> | UserAccountModel): UserAccountDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      username: unwrapped.username,
      addresses: unwrapped.addresses,
    } as UserAccountDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UserAccountModel, can not map to UserAccountDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetWidgetEventsRequestModel from '../../model/get-widget-events-request-model';
import GetWidgetEventRegistrationQuestionsRequestModel from '../../model/get-widget-event-registration-questions-request-model';
import WidgetRegisterForEventRequestModel from '../../model/widget-register-for-event-request-model';
import GetWidgetEventDetailRequestModel from '../../model/get-widget-event-detail-request-model';
import GetWidgetCountriesRequestModel from '../../model/get-widget-countries-request-model';
import WidgetEventListPagingResultModel from '../../model/widget-event-list-paging-result-model';
import DynamicFormFieldDefinitionListResultModel from '../../model/dynamic-form-field-definition-list-result-model';
import WidgetEventModel from '../../model/widget-event-model';
import Int64LookupListResultModel from '../../model/int64-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getWidgetEvents(request: GetWidgetEventsRequestModel, config?: RequestConfig) {
    const requestDTO = GetWidgetEventsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-events'] ?? 'WidgetEvents';
    const response = await service.post<any>(endpointPath, {
      body: {
        searchCriteria: requestDTO.searchCriteria,
        paging: requestDTO.paging,
        toccoFilter: requestDTO.toccoFilter,
        searchTag: requestDTO.searchTag,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WidgetEventListPagingResultModel.toModel(dto));
  },
  /**  */
  async getWidgetEventRegistrationQuestions(request: GetWidgetEventRegistrationQuestionsRequestModel, config?: RequestConfig) {
    const requestDTO = GetWidgetEventRegistrationQuestionsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-event-registration-questions'] ?? 'WidgetEvents/{id}/RegistrationQuestions';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(DynamicFormFieldDefinitionListResultModel.toModel(dto));
  },
  /**  */
  async widgetRegisterForEvent(request: WidgetRegisterForEventRequestModel, config?: RequestConfig) {
    const requestDTO = WidgetRegisterForEventRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-widget-register-for-event'] ?? 'WidgetEvents/{id}/Register';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.post<any>(endpointPath, {
      body: {
        eventId: requestDTO.eventId,
        contactAddress: requestDTO.contactAddress,
        invoiceAddress: requestDTO.invoiceAddress,
        correspondenceAddress: requestDTO.correspondenceAddress,
        registrationAnswers: requestDTO.registrationAnswers,
        employmentPosition: requestDTO.employmentPosition,
        employmentArea: requestDTO.employmentArea,
        employmentGradePercent: requestDTO.employmentGradePercent,
        employmentStartDate: requestDTO.employmentStartDate,
        comment: requestDTO.comment,
        marketingAnswers: requestDTO.marketingAnswers,
        requestSummary: requestDTO.requestSummary,
        acceptNewsletter: requestDTO.acceptNewsletter,
        acceptGdpr: requestDTO.acceptGdpr,
        languageIso2: requestDTO.languageIso2,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getWidgetEventDetail(request: GetWidgetEventDetailRequestModel, config?: RequestConfig) {
    const requestDTO = GetWidgetEventDetailRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-event-detail'] ?? 'WidgetEvents/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WidgetEventModel.toModel(dto));
  },
  /**  */
  async getWidgetCountries(request: GetWidgetCountriesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-countries'] ?? 'WidgetEvents/countries';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int64LookupListResultModel.toModel(dto));
  },
});

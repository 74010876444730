import Vue from 'vue';
import WidgetEventModelBase from '../_generated/modelBases/widget-event-model-base';

export function getAvailabilityString(status: string | undefined | null): string {
  switch (status) {
    case 'bookable':
      return Vue.$t('widget.events.status.bookable');
    case 'waitinglist':
      return Vue.$t('widget.events.status.waitinglist');
    case 'bookedUp':
      return Vue.$t('widget.events.status.bookedUp');
    default:
      return Vue.$t('widget.events.status.default');
  }
}

export default class WidgetEventModel extends WidgetEventModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs

  public get availabilityString():string {
    return getAvailabilityString(this.registrationStatus);
  }

  public get hasDocuments():boolean {
    return this.eventDocumentsUrl != null
      || this.locationMapUrl != null
      || this.eventInformationDocumentUrl != null
      || this.registrationFormUrl != null;
  }
}

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateOrderRequestModel from '../../model/create-order-request-model';
import CreateOrderRequestDTO from '../dto/create-order-request-dto';
import dtoSchema from '../schemas/create-order-request-schema';
import BasketModel from '../../model/basket-model';

export default abstract class CreateOrderRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateOrderRequestDTO>) {
    super();
    if (data) {
      this.userId = data.userId ?? undefined;
      this.wantsToBecomeAMember = data.wantsToBecomeAMember;
      this.priceCategory = data.priceCategory ?? undefined;
      this.clientRemark = data.clientRemark ?? undefined;
      this.correspondenceLanguage = data.correspondenceLanguage ?? undefined;
      this.basket = data.basket ? BasketModel.toModel(data.basket) : undefined;
    }
  }
  /**
  */
  userId?: string;
  /**
  */
  wantsToBecomeAMember?: boolean;
  /**
  */
  priceCategory?: string;
  /**
  */
  clientRemark?: string;
  /**
  */
  correspondenceLanguage?: string;
  /**
  */
  basket?: BasketModel;

  static toModel(dto: DeepPartial<CreateOrderRequestDTO>): CreateOrderRequestModel;
  static toModel(dto: DeepPartial<CreateOrderRequestDTO> | undefined | null): CreateOrderRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateOrderRequestDTO> | undefined | null): CreateOrderRequestModel | undefined {
    return dto ? new CreateOrderRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateOrderRequestModel> | CreateOrderRequestModel): CreateOrderRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      userId: unwrapped.userId,
      wantsToBecomeAMember: unwrapped.wantsToBecomeAMember,
      priceCategory: unwrapped.priceCategory,
      clientRemark: unwrapped.clientRemark,
      correspondenceLanguage: unwrapped.correspondenceLanguage,
      basket: unwrapped.basket,
    } as CreateOrderRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateOrderRequestModel, can not map to CreateOrderRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

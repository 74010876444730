// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UpdateBasketRequestModel from '../../model/update-basket-request-model';
import UpdateBasketRequestDTO from '../dto/update-basket-request-dto';
import dtoSchema from '../schemas/update-basket-request-schema';
import BasketItemModel from '../../model/basket-item-model';
import AddressModel from '../../model/address-model';
import BasketSummaryModel from '../../model/basket-summary-model';

export default abstract class UpdateBasketRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<UpdateBasketRequestDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.basketItems = data.basketItems?.map((i) => BasketItemModel.toModel(i)) ?? [];
      this.addresses = data.addresses?.map((i) => AddressModel.toModel(i)) ?? [];
      this.isShippingSameAsInvoice = data.isShippingSameAsInvoice;
      this.summary = data.summary?.map((i) => BasketSummaryModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  basketItems?: BasketItemModel[];
  /**
  */
  addresses?: AddressModel[];
  /**
  */
  isShippingSameAsInvoice?: boolean;
  /**
  */
  summary?: BasketSummaryModel[];

  static toModel(dto: DeepPartial<UpdateBasketRequestDTO>): UpdateBasketRequestModel;
  static toModel(dto: DeepPartial<UpdateBasketRequestDTO> | undefined | null): UpdateBasketRequestModel | undefined;
  static toModel(dto: DeepPartial<UpdateBasketRequestDTO> | undefined | null): UpdateBasketRequestModel | undefined {
    return dto ? new UpdateBasketRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<UpdateBasketRequestModel> | UpdateBasketRequestModel): UpdateBasketRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      basketItems: unwrapped.basketItems,
      addresses: unwrapped.addresses,
      isShippingSameAsInvoice: unwrapped.isShippingSameAsInvoice,
      summary: unwrapped.summary,
    } as UpdateBasketRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for UpdateBasketRequestModel, can not map to UpdateBasketRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

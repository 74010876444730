<template>
  <p-container class="widget-shop-testing-page page automation test">
    <il-widget-tester
      v-model="page.widgetConfig"
      title="Shop Widget Test"
    ></il-widget-tester>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import IlWidgetTester from '../../../../../components/il-widget-tester.vue';
  import WidgetShopTestingPage from './widget-shop-testing.vue.model';

  export default Vue.extend({
    name: 'WidgetShopTesting',
    role: ['Admin', 'Hostadmin'],
    auth: true,
    components: {
      IlWidgetTester,
    },
    data: () => ({
      page: new WidgetShopTestingPage(),
    }),
    async mounted() {
      await this.page.initialize();
    },
  });
</script>

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ArticleModel from '../../model/article-model';
import ArticleDTO from '../dto/article-dto';
import dtoSchema from '../schemas/article-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import PriceModel from '../../model/price-model';
import ArticleVariantModel from '../../model/article-variant-model';

export default abstract class ArticleModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ArticleDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.title = data.title ?? undefined;
      this.description = data.description ?? undefined;
      this.detailDescription = data.detailDescription ?? undefined;
      this.articleNumber = data.articleNumber;
      this.abbreviation = data.abbreviation ?? undefined;
      this.dimensions = data.dimensions ?? undefined;
      this.weight = data.weight ?? undefined;
      this.prices = data.prices?.map((i) => PriceModel.toModel(i)) ?? [];
      this.imageDetailId = data.imageDetailId ?? undefined;
      this.imagePreviewId = data.imagePreviewId ?? undefined;
      this.variants = data.variants?.map((i) => ArticleVariantModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  title?: string;
  /**
  */
  description?: string;
  /**
  */
  detailDescription?: string;
  /**
  * @type {int32}
  */
  articleNumber?: number;
  /**
  */
  abbreviation?: string;
  /**
  */
  dimensions?: string;
  /**
  * @type {double}
  */
  weight?: number;
  /**
  */
  prices?: PriceModel[];
  /**
  */
  imageDetailId?: string;
  /**
  */
  imagePreviewId?: string;
  /**
  */
  variants?: ArticleVariantModel[];

  static toModel(dto: DeepPartial<ArticleDTO>): ArticleModel;
  static toModel(dto: DeepPartial<ArticleDTO> | undefined | null): ArticleModel | undefined;
  static toModel(dto: DeepPartial<ArticleDTO> | undefined | null): ArticleModel | undefined {
    return dto ? new ArticleModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ArticleModel> | ArticleModel): ArticleDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      title: unwrapped.title,
      description: unwrapped.description,
      detailDescription: unwrapped.detailDescription,
      articleNumber: unwrapped.articleNumber,
      abbreviation: unwrapped.abbreviation,
      dimensions: unwrapped.dimensions,
      weight: unwrapped.weight,
      prices: unwrapped.prices,
      imageDetailId: unwrapped.imageDetailId,
      imagePreviewId: unwrapped.imagePreviewId,
      variants: unwrapped.variants,
    } as ArticleDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ArticleModel, can not map to ArticleDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

import Vue from 'vue';
import WidgetEventListModelBase from '../_generated/modelBases/widget-event-list-model-base';
import { getAvailabilityString } from './widget-event-model';

export default class WidgetEventListModel extends WidgetEventListModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs

  public get priceString():string {
    if (this.price === undefined) { return Vue.$t('widget.events.noCosts'); }
    return Vue.$format.currency(this.price!)!;
  }

  public get timeString():string | undefined {
    if (this.occurences && this.occurences.length > 0) {
      return this.occurences[0].timeString;
    }
    return '';
  }

  public get availabilityString():string {
    return getAvailabilityString(this.registrationStatus);
  }

  public get isBookingActionAvailable():boolean {
    return this.registrationStatus !== 'bookedUp' && this.isOnlineRegistrationActive === true;
  }

  public get hasAppointments():boolean {
    if (Vue._.isSet(this.occurences) && this.occurences.length > 0) {
      return true;
    }
    return false;
  }
}

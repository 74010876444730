import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import AddressModelBase from '../_generated/modelBases/address-model-base';

export default class AddressModel extends AddressModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs

  schema = {
    ...queryBuilder.properties({
      email: { required: true, pattern: validationPatterns.email },
      firstname: { required: true },
      lastname: { required: true },
      postCode: { required: true },
      city: { required: true },
      street: { required: true },
    }),
  } as IJSONSchema;
}

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/address-schema',
  type: 'object',
  required: [],
  nullable: true,
  properties: {
    addressType: {
      enum: [
        0,
        1,
      ],
    },
    city: {
      type: 'string',
      nullable: true,
    },
    company: {
      type: 'string',
      nullable: true,
    },
    email: {
      type: 'string',
      nullable: true,
    },
    fax: {
      type: 'string',
      nullable: true,
    },
    firstname: {
      type: 'string',
      nullable: true,
    },
    lastname: {
      type: 'string',
      nullable: true,
    },
    sex: {
      type: 'string',
      nullable: true,
    },
    poBox: {
      type: 'string',
      nullable: true,
    },
    postCode: {
      type: 'string',
      nullable: true,
    },
    street: {
      type: 'string',
      nullable: true,
    },
    telephone: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;

<template>
  <div class="ilShopArticleDetail">
    <div class="sectionArticleImage">
      <il-shop-article-image
        :title="articleData.title"
        :src="shoppingBasket.getDetailArticleImageUrl(articleData)"
      ></il-shop-article-image>
    </div>
    <div class="sectionArticleInfo">
      <div v-if="errorResponse" class="articleError error">
        <p-request-alert v-model="errorResponse"></p-request-alert>
        <p-alert type="warning" :value="articleNotFound">
          <span>Artikel wurde nicht gefunden oder ist nicht verfügbar</span>
          <p-button @click.prevent="$router.back()">
            <p-icon left>
              arrow_back
            </p-icon>
            {{ $t('core.app.back') }}
          </p-button>
        </p-alert>
      </div>
      <p class="articleInfo">
        <span class="articleTitle">{{ articleData ? articleData.title : '' }}</span><br>
        <span class="articleNumber">
          {{ $t('shop.article.abbreviation') }}: {{ articleData.abbreviation }}
        </span>
      </p>
      <p class="articleDescription">
        {{ articleData ? articleData.description : '' }}
      </p>
      <p class="articleDetailDescription">
        {{ articleData ? articleData.detailDescription : '' }}
      </p>
    </div>
    <div class="sectionArticlePricing">
      <div v-if="articleData" class="articlePrices">
        <div v-if="memberPrice" class="memberPriceWrapper">
          <span class="priceLabel memberPriceLabel">{{ $t('shop.price.member') }}:</span>
          <span class="price memberPrice">{{ $format.currency(memberPrice.netPrice, 'CHF') }}</span>
        </div>
        <div v-if="standardPrice" class="standardPriceWrapper">
          <span class="priceLabel standardPriceLabel">{{ $t('shop.price.standard') }}:</span>
          <span class="price standardPrice">{{ $format.currency(standardPrice.netPrice, 'CHF') }}</span>
        </div>
      </div>
      <div class="articleVariants">
        <p-button
          v-for="variant in articleData.variants"
          :key="variant.title"
          :disabled="variant.disabled"
          :loading="variant.loading"
          class="variantButton"
          :class="{ [`variant-${variant.article.id}`]: true, active: `${value.article.id}` !== `${variant.article.id}` }"
          @click="gotoVariant(variant.article.id)"
        >
          {{ variant.title }}
        </p-button>
      </div>
      <div class="sectionArticleActions">
        <slot :article="articleData">
          <p-button
            class="addToBasketButton"
            :disabled="articleNotFound || internalLoading"
            @click.prevent="addToBasket(articleData)"
          >
            {{ $t('shop.article.addToBasket') }}
          </p-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import _ from '@glittr/frontend-core/src/utils';
  import Vue from 'vue';
  import GetByArticleNumberRequestModel from '@/src/services/v2/model/get-by-article-number-request-model';
  import GetArticleByIdRequestModel from '@/src/services/v2/model/get-article-by-id-request-model';
  import ArticleModel from '@/src/services/v2/model/article-model';
  import PriceModel from '@/src/services/v2/model/price-model';
  import shoppingBasket from '../../utilities/shoppingBasket';
  import IlShopArticleImage from './il-shop-article-image.vue';

  export default Vue.extend({
    name: 'IlShopArticleDetail',
    components: {
      IlShopArticleImage,
    },
    props: {
      value: { type: Object, default: undefined },
      loading: { type: Boolean, default: false },
    },
    data: () => ({
      shoppingBasket,
      internalLoading: false,
      articleData: new ArticleModel(),
      errorResponse: undefined as any,
    }),
    computed: {
      imageWidth() {
        return this.$media.isMobile || this.$media.isTablet ? 200 : 300;
      },
      memberPrice(): PriceModel | undefined {
        if (_.isEmpty(this.articleData)) {
          return undefined;
        }
        if (_.isNil(this.articleData.prices)) {
          return undefined;
        }
        return this.articleData.prices.find(
          (price: any) => price.priceType === 'member',
        );
      },
      standardPrice(): PriceModel | undefined {
        if (_.isEmpty(this.articleData)) {
          return undefined;
        }
        if (_.isNil(this.articleData.prices)) {
          return undefined;
        }
        return this.articleData.prices.find(
          (price: any) => price.priceType === 'standard',
        );
      },
      articleNotFound(): boolean {
        if (!this.articleData) {
          return true;
        }
        const noTitleOrDescription = !this.articleData.title && !this.articleData.description;
        const notLoading = !this.internalLoading;
        return (notLoading && noTitleOrDescription);
      },
      computedLoading(): boolean {
        return this.loading || this.internalLoading;
      },
    },
    mounted() {
      this.populateData();
    },
    methods: {
      gotoVariant(id: number | string) {
        this.$router.replace(`/_widget/shop/article-detail?articleid=${id}`);
      },
      addToBasket(article: any) {
        shoppingBasket.add(article);
      },
      async populateData() {
        if (_.isNil(this.value) || _.isEmpty(this.value.article.id)) {
          this.$log.warning('No article number was given, unable to load article data');
          this.errorResponse = 'No article number was given, unable to load article data';
          return;
        }
        this.internalLoading = true;
        try {
          const articleId = typeof this.value.article.id === 'string' ? parseInt(this.value.article.id, 10) : this.value.article.id;
          const reqById = new GetArticleByIdRequestModel({ id: articleId });
          const response = await this.$service.v2.api.shop.getArticleById(reqById);
          this.articleData = response.data;
          this.articleData.variants = this.articleData.variants ?? [];
          for (let i = 0; i < this.articleData.variants.length; i += 1) {
            const variant = this.articleData.variants[i];
            this.$set(this.articleData.variants[i], 'loading', true);
            this.$set(this.articleData.variants[i], 'disabled', true);
            if (variant.article?.id === this.value.article.id) {
              this.$set(this.articleData.variants[i], 'loading', false);
              this.$set(this.articleData.variants[i], 'disabled', false);
              continue;
            }
            const reqByNumber = new GetByArticleNumberRequestModel({ articleNumber: variant.article?.abbreviation });
            this.$service.v2.api.shop.getByArticleNumber(reqByNumber)
              .then((responseArticle) => {
                this.articleData.variants = this.articleData.variants ?? [];
                this.articleData.variants[i].article = responseArticle.data;
                this.$set(this.articleData.variants[i], 'disabled', false);
              })
              .catch((error: any) => {
                if (error && error.status !== 404) {
                  this.$log.error(error);
                }
                this.articleData.variants = this.articleData.variants ?? [];
                this.$set(this.articleData.variants[i], 'disabled', true);
              })
              .finally(() => {
                this.articleData.variants = this.articleData.variants ?? [];
                this.$set(this.articleData.variants[i], 'loading', false);
              });
          }
        } catch (error: any) {
          this.errorResponse = error;
        }
        this.internalLoading = false;
      },
    },
  });
</script>

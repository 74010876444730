<template>
  <p-app theme="glittr widget" class="shop">
    <template #header>
      <div class="shopToolbar dense">
        <p-button class="backButton" @click="goBack()">
          <p-icon left>
            arrow_back
          </p-icon>
          <span>{{ $t('core.app.back') }}</span>
        </p-button>
      </div>
    </template>
    <slot></slot>
  </p-app>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    data: () => ({
    }),
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
      goBack() {
        this.$router.back();
        // Due to a bug the "_startLocation" in the router history is not set when loaded as a widget
        // Check to see if the back actually worked and if not just open the main page
        if (!this.$router.history.pending) {
          this.$router.replace('/_widget/shop/article-list');
        }
      },
    },
  });
</script>

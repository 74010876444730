// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/download-contact-id-mapping-report-request-schema',
  type: 'object',
  required: [],
  properties: {
    customerName: {
      type: 'string',
    },
    foreignAppSystemId: {
      type: 'integer',
    },
    crmStatusId: {
      type: 'integer',
    },
    mappingStatusId: {
      type: 'integer',
    },
    searchCriteria: {
      type: 'string',
    },
  },
} as IJSONSchema;

export default schema;

<template>
  <div class="shopThankYouPage">
    <h2>{{ $t('shop.order.thankyou') }}</h2>
    <div class="sectionOrderComplete">
      <div class="thankYouText">
        <span>{{ $t('shop.order.hasBeenRegistered') }}</span>
      </div>
      <div class="orderNumberText">
        <span>{{ $t('shop.order.yourOrderNumber') }}</span> <span class="orderNumber">{{ orderNumber }}</span>
      </div>
      <div class="sectionOrderCompleteActions">
        <p-button @click="$router.push({ path: '/_widget/shop' });">
          {{ $t('shop.shoppingBasket.continueShopping') }}
        </p-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import shoppingBasket from '../../../../utilities/shoppingBasket';

  export default Vue.extend({
    layout: 'layout-widget-shop-default',
    data: () => ({
    }),
    computed: {
      orderNumber() {
        return shoppingBasket.getLastOrderNumber();
      },
    },
    mounted() {
      this.$routerUtils.updateQueryParams({ page: undefined }, { preserve: true });
    },
    methods: {
    },
  });
</script>

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import CategoryListSchema from './category-list-schema';
import CategoryParentReferenceSchema from './category-parent-reference-schema';

const schema = {
  $id: '/category-list-schema',
  type: 'object',
  required: [],
  properties: {
    id: {
      type: 'string',
      nullable: true,
    },
    title: {
      type: 'string',
      nullable: true,
    },
    categories: {
      type: 'array',
      nullable: true,
      items: { ...CategoryListSchema },
    },
    articles: {
      type: 'array',
      nullable: true,
      items: {
        type: 'string',
      },
    },
    parent: {
      ...CategoryParentReferenceSchema,
    },
    breadcrumb: {
      type: 'array',
      nullable: true,
      items: { ...CategoryParentReferenceSchema },
    },
  },
} as IJSONSchema;

export default schema;

<template>
  <p-row class="ilShopArticleListActions">
    <p-button class="addToBasketButton" :disabled="articleNotFound" @click.prevent="addToBasket(value)">
      {{ $t('shop.article.addToBasket') }}
    </p-button>
    <p-button class="viewDetailsButton" :disabled="articleNotFound" @click.prevent="viewDetail(value)">
      {{ $t('shop.article.viewDetails') }}
    </p-button>
  </p-row>
</template>

<script lang="ts">
  import Vue from 'vue';
  import shoppingBasket from '../../utilities/shoppingBasket';

  export default Vue.extend({
    name: 'IlShopArticleListActions',
    props: {
      value: { type: Object, default: undefined },
    },
    computed: {
      articleNotFound(): any {
        return this.value.error;
      },
    },
    methods: {
      viewDetail(item: any) {
        this.$router.push(`/_widget/shop/article-detail?articleid=${item.id}`);
      },
      addToBasket(item: any) {
        shoppingBasket.add(item);
      },
    },

  });
</script>

<template>
  <!-- Begin Widget.SearchCourses -->
  <div class="search angebote">
    <div class="inputfilter">
      <label for="txtSearchCourses">{{ $t('widget.events.searchCourse') }}<br>
        <input
          id="txtSearchCourses"
          v-model="searchTerm"
          type="text"
          @input="onSearchInput"
          @keypress.enter.prevent="onSearchInput"
        />
        <a @click="clearSearch" @keyup.enter="clearSearch">{{ $t('widget.events.allOffers') }}</a>
      </label>
    </div>
    <div class="liste angebote">
      <widget-event-card v-for="e in courses" :key="e.id" :value="e"></widget-event-card>
      <div v-if="!isResultAvailable && !isLoading" class="item">
        <div class="image">
          <div class="backgroundimage" :style="{ 'background-image': `url(/Portals/0/Inhalte/_system/widget-standard-angebot.png)` }"></div>
        </div>
        <div class="text_wrapper">
          <div class="row">
            <div class="col-12">
              <div class="titel">
                {{ $t('widget.events.noEntriesFoundTitle') }}
              </div>
              <div>
                <p
                  v-html="$config.getValueTranslated('widget-tocco-text-event-search-no-entries', {})"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="loading">
        <progress id="file" indeterminate="true"></progress>
      </div>

      <div class="debug">
        data-widget-toccofilter: {{ $config.values['widget-toccofilter'] }}<br>
        data-widget-pagesize: {{ $config.values['widget-pagesize'] }}<br>
        data-language: {{ $config.values.language }}<br>
        searchTerm: {{ searchTerm }}<br>
        searchTag: {{ searchTag }}<br>
      </div>
    </div>
  </div>
  <!-- End Widget.NextCourses -->
</template>

<script lang="ts">
  import Vue from 'vue';
  import WidgetEventListModel from '@/src/services/v2/model/widget-event-list-model';
  import GetWidgetEventsRequestModel from '@/src/services/v2/model/get-widget-events-request-model';
  import ApiPagingRequestModel from '@/src/services/v2/model/api-paging-request-model';
  import widgetEventCard from '@/src/components/widget-event-card.vue';

  export default Vue.extend({
    components: { widgetEventCard },
    layout: 'layout-widget-no-design',
    data: () => ({
      courses: [] as WidgetEventListModel[],
      searchTerm: '' as string,
      searchTag: '' as string,
      isLoading: true as boolean,
      isTermineVisible: [] as boolean[],
    }),
    computed: {
      toccoFilter(): string {
        return this.$config.values['widget-toccofilter'] ?? '1==1';
      },
      pageSize():number {
        if (this.$config.values['widget-pagesize'] !== undefined) { return this.$config.values['widget-pagesize']; }
        return 10;
      },
      isResultAvailable():boolean {
        if (this.courses.length === 0) { return false; }
        return true;
      },
    },
    async mounted() {
      const qp = this.$routerUtils.getQueryParams();
      if (qp.search !== undefined) {
        this.searchTerm = qp.search;
      }
      if (qp.tag !== undefined) {
        this.searchTag = qp.tag;
      }
      this.doSearch();
    },
    methods: {
      async onSearchInput() {
        this.$debounce(this.doSearch, 500, this)();
      },
      async clearSearch() {
        this.searchTerm = '';
        this.doSearch();
      },
      async doSearch() {
        try {
          this.isLoading = true;
          const req = new GetWidgetEventsRequestModel();
          req.searchCriteria = this.searchTerm;
          req.searchTag = this.searchTag;
          req.toccoFilter = this.toccoFilter;
          const page = new ApiPagingRequestModel();
          page.pageSize = this.pageSize;
          page.currentPage = 1;
          req.paging = page;
          const response = await this.$service.v2.api.widgetEvents.getWidgetEvents(req);
          this.courses = response.items.map((i) => i.data);
        } catch (e) {
          this.$log.error(e);
        } finally {
          this.isLoading = false;
        }
      },
      toggleTermine(id:number) {
        this.$set(this.isTermineVisible, id, !this.isTermineVisible[id]);
      },
    },
  });
</script>

<template>
  <p-text-field
    context-icon="email"
    class="ilShopEmailField"
    :disabled="disabled"
    :label="label"
    :errors="internalErrorMessages"
    :rules="rules"
    :value="value"
    :required="required"
    type="email"
    @input="onInput"
    @blur="onBlur"
  ></p-text-field>
</template>

<script lang="ts">
  import _ from '@glittr/frontend-core/src/utils';
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      value: { type: String, default: undefined },
      label: { type: String, default: undefined },
      required: { type: Boolean, default: false },
      valid: { type: Boolean, default: true },
      errors: { type: undefined, default: undefined },
      rules: { type: undefined, default: undefined },
      disabled: { type: Boolean, default: false },
    },
    data: () => ({
      isError: false,
      internalErrorMessages: undefined as string[] | undefined,
    }),
    watch: {
      errors() {
        this.internalErrorMessages = this.errors;
      },
    },
    mounted() {
      this.$nextTick(() => {
        if (!_.isEmpty(this.value)) {
          this.onBlur();
        }
        const emailIsValid = this.validateEmail(this.value);
        this.$emit('update:valid', emailIsValid);
      });
    },
    methods: {
      validateEmail(email: string) {
        if (_.isNil(this.required) && _.isEmpty(email)) {
          return true;
        }
        const re = /.@./;
        const isValid = re.test(String(email).toLowerCase());
        return isValid;
      },
      onInput(value: string) {
        this.isError = false;
        this.internalErrorMessages = undefined;
        this.$emit('input', value);
        const emailIsValid = this.validateEmail(value);
        this.$emit('update:valid', emailIsValid);
      },
      onBlur() {
        this.isError = false;
        this.internalErrorMessages = undefined;
        const emailIsValid = this.validateEmail(this.value);
        if (!emailIsValid) {
          this.isError = true;
          this.internalErrorMessages = [this.$t('shop.inputErrors.email')];
        }
        this.$emit('input', this.value);
        this.$emit('update:valid', emailIsValid);
      },
    },
  });
</script>

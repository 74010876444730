// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetContactIdMappingsRequestModel from '../../model/get-contact-id-mappings-request-model';
import GetContactIdMappingByIdRequestModel from '../../model/get-contact-id-mapping-by-id-request-model';
import PatchContactIdMappingRequestModel from '../../model/patch-contact-id-mapping-request-model';
import DownloadContactIdMappingReportRequestModel from '../../model/download-contact-id-mapping-report-request-model';
import ContactIdMappingListPagingResultModel from '../../model/contact-id-mapping-list-paging-result-model';
import ContactIdMappingModel from '../../model/contact-id-mapping-model';

export default (service: Servicelayer) => ({
  /**  */
  async getContactIdMappings(request: GetContactIdMappingsRequestModel, config?: RequestConfig) {
    const requestDTO = GetContactIdMappingsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-contact-id-mappings'] ?? 'core/integration/ContactIdMappings';
    const response = await service.get<any>(endpointPath, {
      query: {
        foreignAppSystemIdCriteria: requestDTO.foreignAppSystemIdCriteria,
        crmStatusIdCriteria: requestDTO.crmStatusIdCriteria,
        mappingStatusIdCriteria: requestDTO.mappingStatusIdCriteria,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ContactIdMappingListPagingResultModel.toModel(dto));
  },
  /**  */
  async getContactIdMappingById(request: GetContactIdMappingByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetContactIdMappingByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-contact-id-mapping-by-id'] ?? 'core/integration/ContactIdMappings/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ContactIdMappingModel.toModel(dto));
  },
  /**  */
  async patchContactIdMapping(request: PatchContactIdMappingRequestModel, config?: RequestConfig) {
    const requestDTO = PatchContactIdMappingRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-patch-contact-id-mapping'] ?? 'core/integration/ContactIdMappings/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.patch<any>(endpointPath, {
      query: {
        id: requestDTO.id,
        crmId: requestDTO.crmId,
        crmStateId: requestDTO.crmStateId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async downloadContactIdMappingReport(request: DownloadContactIdMappingReportRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadContactIdMappingReportRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-contact-id-mapping-report'] ?? 'core/integration/ContactIdMappings/DownloadContactIdMappingReport';
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      query: {
        customerName: requestDTO.customerName,
        foreignAppSystemId: requestDTO.foreignAppSystemId,
        crmStatusId: requestDTO.crmStatusId,
        mappingStatusId: requestDTO.mappingStatusId,
        searchCriteria: requestDTO.searchCriteria,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});

<template>
  <div class="ilShopLayeredNavigation">
    <div class="breadcrumb">
      <button
        v-for="(layer, layerIndex) in layers"
        :key="layer.title"
        type="button"
        :class="`navigationEntry depth${layerIndex + 1}`"
        @click="goto(layer.to)"
      >
        <span class="navigationEntryTitle">{{ layer.title }}</span>
        <p-icon v-if="layerIndex + 1 === layers.length" class="navigationEntryExpander" spacious>
          expand_more
        </p-icon>
      </button>
    </div>
    <transition v-for="item in items" :key="item.title" :appear="true" name="slide-fade">
      <button :key="item.title" class="navigationEntry" type="button" @click="goto(item.to)">
        {{ item.title }}
      </button>
    </transition>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'IlShopLayeredNavigation',
    props: {
      items: { type: [Array, Object], default: undefined },
      layers: { type: Array, default: undefined },
    },
    methods: {
      goto(route: string) {
        if (route !== this.$router.currentRoute.fullPath) {
          this.$router.push(route);
        }
      },
    },
  });
</script>

<style scoped lang="scss">
    .slide-fade-enter-active {
        transition: all .8s ease;
        transition-delay: 270ms;
        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            transition-delay: 15ms * ($i * 1.75) !important;
          }
        }
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateY(100%);
        opacity: 0;
    }
</style>

<template>
  <!-- Begin Widget.NextCourses -->
  <div class="liste angebote">
    <widget-event-card v-for="e in courses" :key="e.id" :value="e"></widget-event-card>
    <div v-if="!isResultAvailable" class="item">
      <div class="image">
        <div class="backgroundimage" :style="{ 'background-image': `url(/Portals/0/Inhalte/_system/widget-standard-angebot.png)` }"></div>
      </div>
      <div class="text_wrapper">
        <div class="row">
          <div class="col-12">
            <div class="titel">
              {{ $t('widget.events.noEntriesFoundTitle') }}
            </div>
            <div>
              <p v-html="$t('widget.events.noEntriesFoundDescription')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="debug">
      data-widget-toccofilter: {{ $config.values['widget-toccofilter'] }}<br>
      data-widget-pagesize: {{ $config.values['widget-pagesize'] }}<br>
      data-language: {{ $config.values.language }}
    </div>
  </div>
  <!-- End Widget.NextCourses -->
</template>

<script lang="ts">
  import Vue from 'vue';
  import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
  import WidgetEventListModel from '@/src/services/v2/model/widget-event-list-model';
  import GetWidgetEventsRequestModel from '@/src/services/v2/model/get-widget-events-request-model';
  import ApiPagingRequestModel from '@/src/services/v2/model/api-paging-request-model';
  import widgetEventCard from '@/src/components/widget-event-card.vue';

  export default Vue.extend({
    components: { widgetEventCard },
    layout: 'layout-widget-no-design',
    data: () => ({
      courses: [] as WidgetEventListModel[],
      isTermineVisible: [] as boolean[],
    }),
    computed: {
      toccoFilter(): string {
        return this.$config.values['widget-toccofilter'] ?? '1==1';
      },
      pageSize():number {
        if (this.$config.values['widget-pagesize'] !== undefined) { return this.$config.values['widget-pagesize']; }
        return 10;
      },
      isResultAvailable():boolean {
        if (this.courses.length === 0) { return false; }
        return true;
      },
    },
    async mounted() {
      try {
        const req = new GetWidgetEventsRequestModel();
        req.toccoFilter = this.toccoFilter;
        const page = new ApiPagingRequestModel();
        page.pageSize = this.pageSize;
        page.currentPage = 1;
        req.paging = page;
        const response = await this.$service.v2.api.widgetEvents.getWidgetEvents(req);
        this.courses = response.items.map((i) => i.data);
      } catch (e) {
        const errorResponse = e as ServiceResponse;
        if (errorResponse.status === 503) {
          // = errorResponse.data;
        }
        this.$log.error(e);
      }
    },
  });
</script>

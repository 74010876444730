// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WidgetRegisterForEventRequestModel from '../../model/widget-register-for-event-request-model';
import WidgetRegisterForEventRequestDTO from '../dto/widget-register-for-event-request-dto';
import dtoSchema from '../schemas/widget-register-for-event-request-schema';
import ContactAddressModel from '../../model/contact-address-model';
import ContactCorrespondenceAddressModel from '../../model/contact-correspondence-address-model';
import DynamicFormPayloadModel from '../../model/dynamic-form-payload-model';
import DynamicFormFieldPayloadModel from '../../model/dynamic-form-field-payload-model';
import RequestSummaryModel from '../../model/request-summary-model';

export default abstract class WidgetRegisterForEventRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WidgetRegisterForEventRequestDTO>) {
    super();
    if (data) {
      this.eventId = data.eventId;
      this.contactAddress = ContactAddressModel.toModel(data.contactAddress);
      this.invoiceAddress = data.invoiceAddress ? ContactCorrespondenceAddressModel.toModel(data.invoiceAddress) : undefined;
      this.correspondenceAddress = data.correspondenceAddress ? ContactCorrespondenceAddressModel.toModel(data.correspondenceAddress) : undefined;
      this.registrationAnswers = data.registrationAnswers ? DynamicFormPayloadModel.toModel(data.registrationAnswers) : undefined;
      this.employmentPosition = data.employmentPosition ?? undefined;
      this.employmentArea = data.employmentArea ?? undefined;
      this.employmentGradePercent = data.employmentGradePercent;
      this.employmentStartDate = data.employmentStartDate ?? undefined;
      this.comment = data.comment ?? undefined;
      this.marketingAnswers = data.marketingAnswers?.map((i) => DynamicFormFieldPayloadModel.toModel(i)) ?? [];
      this.requestSummary = data.requestSummary ? RequestSummaryModel.toModel(data.requestSummary) : undefined;
      this.acceptNewsletter = data.acceptNewsletter;
      this.acceptGdpr = data.acceptGdpr;
      this.languageIso2 = data.languageIso2 ?? undefined;
      this.id = data.id;
    }
  }
  /**
  * @type {int32}
  */
  eventId?: number;
  /**
  */
  contactAddress?: ContactAddressModel;
  /**
  */
  invoiceAddress?: ContactCorrespondenceAddressModel;
  /**
  */
  correspondenceAddress?: ContactCorrespondenceAddressModel;
  /**
  */
  registrationAnswers?: DynamicFormPayloadModel;
  /**
  */
  employmentPosition?: string;
  /**
  */
  employmentArea?: string;
  /**
  * @type {int32}
  */
  employmentGradePercent?: number;
  /**
  * @type {date-time}
  */
  employmentStartDate?: string;
  /**
  */
  comment?: string;
  /**
  */
  marketingAnswers?: DynamicFormFieldPayloadModel[];
  /**
  */
  requestSummary?: RequestSummaryModel;
  /**
  */
  acceptNewsletter?: boolean;
  /**
  */
  acceptGdpr?: boolean;
  /**
  */
  languageIso2?: string;
  /**
  * @type {int32}
  */
  id?: number;

  static toModel(dto: DeepPartial<WidgetRegisterForEventRequestDTO>): WidgetRegisterForEventRequestModel;
  static toModel(dto: DeepPartial<WidgetRegisterForEventRequestDTO> | undefined | null): WidgetRegisterForEventRequestModel | undefined;
  static toModel(dto: DeepPartial<WidgetRegisterForEventRequestDTO> | undefined | null): WidgetRegisterForEventRequestModel | undefined {
    return dto ? new WidgetRegisterForEventRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WidgetRegisterForEventRequestModel> | WidgetRegisterForEventRequestModel): WidgetRegisterForEventRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      eventId: unwrapped.eventId,
      contactAddress: unwrapped.contactAddress,
      invoiceAddress: unwrapped.invoiceAddress,
      correspondenceAddress: unwrapped.correspondenceAddress,
      registrationAnswers: unwrapped.registrationAnswers,
      employmentPosition: unwrapped.employmentPosition,
      employmentArea: unwrapped.employmentArea,
      employmentGradePercent: unwrapped.employmentGradePercent,
      employmentStartDate: unwrapped.employmentStartDate,
      comment: unwrapped.comment,
      marketingAnswers: unwrapped.marketingAnswers,
      requestSummary: unwrapped.requestSummary,
      acceptNewsletter: unwrapped.acceptNewsletter,
      acceptGdpr: unwrapped.acceptGdpr,
      languageIso2: unwrapped.languageIso2,
      id: unwrapped.id,
    } as WidgetRegisterForEventRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WidgetRegisterForEventRequestModel, can not map to WidgetRegisterForEventRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

<template>
  <p-app theme="glittr widget" class="shop">
    <div class="shopToolbar dense decorative"></div>
    <div>
      <slot></slot>
    </div>
  </p-app>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({});
</script>

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCallLogsRequestModel from '../../model/get-call-logs-request-model';
import GetCallLogByIdRequestModel from '../../model/get-call-log-by-id-request-model';
import GetCallLogStatusCodeLookupRequestModel from '../../model/get-call-log-status-code-lookup-request-model';
import CallLogWithDurationPagingResultModel from '../../model/call-log-with-duration-paging-result-model';
import CallLogWithDurationModel from '../../model/call-log-with-duration-model';
import Int32LookupListResultModel from '../../model/int32-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getCallLogs(request: GetCallLogsRequestModel, config?: RequestConfig) {
    const requestDTO = GetCallLogsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-call-logs'] ?? 'core/admin/CallLogs';
    const response = await service.get<any>(endpointPath, {
      query: {
        correlationId: requestDTO.correlationId,
        from: requestDTO.from,
        to: requestDTO.to,
        requestPath: requestDTO.requestPath,
        requestTypes: requestDTO.requestTypes,
        targetSystems: requestDTO.targetSystems,
        responseStatusCode: requestDTO.responseStatusCode,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CallLogWithDurationPagingResultModel.toModel(dto));
  },
  /**  */
  async getCallLogById(request: GetCallLogByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetCallLogByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-call-log-by-id'] ?? 'core/admin/CallLogs/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CallLogWithDurationModel.toModel(dto));
  },
  /**  */
  async getCallLogStatusCodeLookup(request: GetCallLogStatusCodeLookupRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-call-log-status-code-lookup'] ?? 'core/admin/CallLogs/StatusCodeLookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Int32LookupListResultModel.toModel(dto));
  },
});

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import BasketSummaryModel from '../../model/basket-summary-model';
import BasketSummaryDTO from '../dto/basket-summary-dto';
import dtoSchema from '../schemas/basket-summary-schema';
import ShippingInfoModel from '../../model/shipping-info-model';
import VatSummaryModel from '../../model/vat-summary-model';

export default abstract class BasketSummaryModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<BasketSummaryDTO>) {
    super();
    if (data) {
      this.priceType = data.priceType ?? undefined;
      this.netAmount = data.netAmount;
      this.vatAmount = data.vatAmount;
      this.grossAmount = data.grossAmount;
      this.shippingInfo = data.shippingInfo ? ShippingInfoModel.toModel(data.shippingInfo) : undefined;
      this.grandTotalAmount = data.grandTotalAmount;
      this.totalWeight = data.totalWeight;
      this.totalQuantity = data.totalQuantity;
      this.vatSummaryItems = data.vatSummaryItems?.map((i) => VatSummaryModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  priceType?: string;
  /**
  * @type {double}
  */
  netAmount?: number;
  /**
  * @type {double}
  */
  vatAmount?: number;
  /**
  * @readonly
  * @type {double}
  */
  grossAmount?: number;
  /**
  */
  shippingInfo?: ShippingInfoModel;
  /**
  * @readonly
  * @type {double}
  */
  grandTotalAmount?: number;
  /**
  * @type {double}
  */
  totalWeight?: number;
  /**
  * @type {int32}
  */
  totalQuantity?: number;
  /**
  */
  vatSummaryItems?: VatSummaryModel[];

  static toModel(dto: DeepPartial<BasketSummaryDTO>): BasketSummaryModel;
  static toModel(dto: DeepPartial<BasketSummaryDTO> | undefined | null): BasketSummaryModel | undefined;
  static toModel(dto: DeepPartial<BasketSummaryDTO> | undefined | null): BasketSummaryModel | undefined {
    return dto ? new BasketSummaryModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<BasketSummaryModel> | BasketSummaryModel): BasketSummaryDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      priceType: unwrapped.priceType,
      netAmount: unwrapped.netAmount,
      vatAmount: unwrapped.vatAmount,
      grossAmount: unwrapped.grossAmount,
      shippingInfo: unwrapped.shippingInfo,
      grandTotalAmount: unwrapped.grandTotalAmount,
      totalWeight: unwrapped.totalWeight,
      totalQuantity: unwrapped.totalQuantity,
      vatSummaryItems: unwrapped.vatSummaryItems,
    } as BasketSummaryDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for BasketSummaryModel, can not map to BasketSummaryDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

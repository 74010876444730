import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import Vue from 'vue';
import type { WidgetConfig } from '../../../../../components/il-widget-tester.vue.model';
import GetCategoriesRequestModel from '../../../../../services/v2/model/get-categories-request-model';

export default class WidgetShopTestingPage extends PageBase {
  categorySelection = [] as { id: any, caption: string, depth: number }[];

  pages = [
    { id: 'article-detail', caption: 'article-detail' },
    { id: 'article-list', caption: 'article-list' },
    { id: 'checkout-overview', caption: 'checkout-overview' },
    { id: 'login', caption: 'login' },
    { id: 'register', caption: 'register' },
    { id: 'shopping-basket', caption: 'shopping-basket' },
    { id: 'thankyou', caption: 'thankyou' },
  ];

  get widgetConfig() {
    return {
      route: { value: '/_widget/shop/', hidden: true },
      'shop-page': { label: 'Page (only query)', suggestions: this.pages, hidden: true },
      'shop-articleid': { label: 'Article id (only query)', hidden: true },
      'shop-articlenumber': { label: 'Prefilled article number (search)' },
      'shop-categoryid': { label: 'Prefilled category id', suggestions: this.categorySelection },
    } as WidgetConfig;
  }

  async initialize(): Promise<void> {
    try {
      const req = new GetCategoriesRequestModel();
      const resp = (await Vue.$service.v2.api.shop.getCategories(req))?.data.categories ?? [];
      this.categorySelection = [];
      const queue = resp as (typeof resp[0] & { depth?: number })[];
      while (queue.length) {
        const item = queue.pop();
        const depth = item?.depth ?? 1;
        const paddedStrLen = (item?.title?.length ?? 0) + depth;
        const isParent = !!item?.categories?.length;
        const caption = (isParent ? `${item?.title}`.padStart(paddedStrLen - 1, '─') : `│${item?.title}`.padStart(paddedStrLen, '⠀')) ?? 'Undefined';
        this.categorySelection.push({ id: item?.id, caption, depth: item?.depth ?? 0 });
        if (item?.categories?.length) {
          queue.push(...item.categories.sort((a, b) => (b.categories?.length ?? 0) - (a.categories?.length ?? 0)).map((x) => ({ ...x, depth: depth + 1 } as any)));
        }
      }
    } catch (e: any) {
      Vue.$log.error('Failed to parse categories for suggestions in widget:');
      Vue.$log.error(e);
    }
  }
}

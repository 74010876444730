import { render, staticRenderFns } from "./il-shop-shopping-basket-costs.vue?vue&type=template&id=5850e389"
import script from "./il-shop-shopping-basket-costs.vue?vue&type=script&lang=ts"
export * from "./il-shop-shopping-basket-costs.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <p-app theme="glittr widget" class="shop">
    <template #header>
      <div class="shopToolbar">
        <p-button v-if="isMobile" class="burgerMenu" @click="drawer = !drawer">
          <p-icon>
            menu
          </p-icon>
        </p-button>
        <p-text-field
          v-model="search"
          solo
          clearable
          class="articleSearch"
          :label="$t('core.app.search')"
          context-icon="search"
          @input="performSearch($event)"
        ></p-text-field>
        <div class="actionsWrapper">
          <p-badge
            v-model="shoppingBasketNotEmpty"
            overlap
            class="shoppingBasketBadge"
            bottom
            left
          >
            <template #badge>
              {{ shoppingBasketCount }}
            </template>
            <p-button class="shoppingBasketButton" @click="goToBasket()">
              <p-icon>
                shopping_basket
              </p-icon>
            </p-button>
          </p-badge>
        </div>
      </div>
    </template>
    <p-side-drawer
      v-model="drawer"
      :overlay="isMobile"
      class="shopSideDrawer"
    >
      <il-shop-layered-navigation
        :items="nav"
        :layers="breadcrumb"
      ></il-shop-layered-navigation>
      <template #content>
        <p-request-alert v-if="errorResponse" v-model="errorResponse" dismissable></p-request-alert>
        <slot></slot>
      </template>
    </p-side-drawer>
  </p-app>
</template>

<script lang="ts">
  import shoppingBasket from '@/src/utilities/shoppingBasket';
  import { MediaType } from '@glittr/frontend-core/src/plugins/media/media';
  import Vue from 'vue';
  import GetCategoriesRequestModel from '@/src/services/v2/model/get-categories-request-model';
  import CategoryListModel from '@/src/services/v2/model/category-list-model';
  import IlShopLayeredNavigation from '@/src/components/shop/il-shop-layered-navigation.vue';

  export default Vue.extend({
    components: {
      IlShopLayeredNavigation,
    },
    data() {
      return {
        shopEmbeddedLayout: true,
        search: '',
        shoppingBasketNotEmpty: false,
        shoppingBasketCount: 0,
        isMobile: false,
        clipped: false,
        drawer: true,
        fixed: false,
        errorResponse: undefined,
        nav: {},
        breadcrumb: [] as any[],
        miniVariant: false,
        right: true,
        rightDrawer: false,
      };
    },
    computed: {
      title(): string {
        // return this.$store.state.nav.title;
        return '';
      },
    },
    watch: {
      shoppingBasketCount(newValue) {
        this.shoppingBasketNotEmpty = newValue !== 0;
      },
      '$route.query': {
        handler() {
          this.populateCategories();
        },
      },
    },
    mounted() {
      const search = this.$config.getQueryOrValue('shop-search') ?? this.$config.getQueryOrValue('shop-articlenumber');
      if (!this._.isEmpty(search)) {
        this.$set(this, 'search', search!);
      }

      this.updateBasketCount();
      this.$eventbus.on('UPDATE/SWISSMECHANIC.ONLINESHOP.SHOPPINGBASKET', () => {
        this.updateBasketCount();
      });

      this.$media.addResizeListeners(this.onResize);
      this.populateCategories();
    },
    beforeDestroy() {
      this.$media.removeResizeListeners(this.onResize);
    },
    methods: {
      goToBasket() {
        this.$router.push('/_widget/shop/shopping-basket');
      },
      performSearch(searchQuery: string) {
        const self = this;
        this.$debounce(() => {
          self.$eventbus.emit('search', searchQuery);
        }, 500, self)();
      },
      async getCategory(id: number | string): Promise<CategoryListModel> {
        const req = new GetCategoriesRequestModel();
        const response = await this.$service.v2.api.shop.getCategories(req);
        if (!id) {
          return response.data;
        }
        // Recursion
        const queue = [] as CategoryListModel[];
        queue.push(response.data);
        while (queue.length > 0) {
          const currentCategory = queue.pop()!;
          if (currentCategory.id === id) {
            return currentCategory;
          }
          if (currentCategory.categories) {
            const nextCategories = currentCategory.categories as any;
            queue.push(...nextCategories);
          }
        }
        throw new Error('CategoryNotFoundError');
      },
      populateCategories() {
        const categoryId = this.$config.getQueryOrValue('shop-categoryid') || this.$route.query.category as string;

        if (categoryId && categoryId !== 'undefined') {
          this.getCategory(categoryId)
            .then((response) => {
              this.mapNavigation(response);
            })
            .catch((error: any) => {
              if (error.name === 'CategoryNotFoundError') {
                this.$router.replace('/');
              }
              this.errorResponse = error;
              this.$log.error(error);
            });
        } else {
          const req = new GetCategoriesRequestModel();
          this.$service.v2.api.shop.getCategories(req)
            .then((response) => {
              this.mapNavigation(response.data);
            })
            .catch((error: any) => {
              this.errorResponse = error;
              this.$log.error(error);
            });
        }
      },
      mapNavigation(category: any) {
        const { categories } = category;
        if (categories) {
          this.nav = categories.map((e: any) => ({ title: e.title, to: `/_widget/shop/article-list?categoryid=${e.id}` }));
        }
        if (category.breadcrumb) {
          this.breadcrumb = category.breadcrumb.map((c: any) => ({ title: c.title, to: `/_widget/shop/article-list?categoryid=${c.id}` }));
          this.breadcrumb.push(({ title: category.title, to: `/_widget/shop/article-list?categoryid=${category.id}` }));
        } else {
          this.$log.error(`Unable to retrieve breadcrumb for category: '${category.title}'`);
        }
      },
      isInsideRoute(item: any) {
        let isInsideRoute = false;
        if (item.children) {
          (item.children).forEach((child: any) => {
            if (child.to === this.$route.path) {
              isInsideRoute = true;
            }
          });
        }
        if (isInsideRoute) {
          return true;
        }
        return item.to === this.$route.path;
      },
      changeLanguage(language: string) {
        this.$translation.set(language);
        window.location.reload();
      },
      changeToMobile() {
        if (!this.isMobile) {
          this.isMobile = true;
          this.drawer = false;
          this.miniVariant = false;
        }
      },
      changeToDesktop() {
        if (this.isMobile) {
          this.isMobile = false;
          this.drawer = true;
        }
      },
      onResize(mediaType: MediaType) {
        if (mediaType === 'mobile' || mediaType === 'tablet') {
          this.changeToMobile();
        } else {
          this.changeToDesktop();
        }
      },
      updateBasketCount() {
        this.$nextTick(() => {
          const { basketItems } = shoppingBasket.getLocalBasket();
          const articleInfo = basketItems?.reduce((total: any, article: any) => ({ count: article.count + total.count }), { count: 0 }) ?? { count: 0 };
          this.shoppingBasketCount = articleInfo.count;
        });
      },
    },
  });
</script>

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import PriceSchema from './price-schema';
import ArticleVariantSchema from './article-variant-schema';

const schema = {
  $id: '/article-schema',
  type: 'object',
  required: ['id', 'articleNumber'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    title: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    detailDescription: {
      type: 'string',
      nullable: true,
    },
    articleNumber: {
      type: 'integer',
    },
    abbreviation: {
      type: 'string',
      nullable: true,
    },
    dimensions: {
      type: 'string',
      nullable: true,
    },
    weight: {
      type: 'number',
      nullable: true,
    },
    prices: {
      type: 'array',
      nullable: true,
      items: { ...PriceSchema },
    },
    imageDetailId: {
      type: 'string',
      nullable: true,
    },
    imagePreviewId: {
      type: 'string',
      nullable: true,
    },
    variants: {
      type: 'array',
      nullable: true,
      items: { ...ArticleVariantSchema },
    },
  },
} as IJSONSchema;

export default schema;

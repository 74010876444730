<template>
  <div class="shopArticleDetailPage">
    <div class="articleDetailWrapper">
      <p-button class="backButton" @click="goBack">
        <p-icon left>
          arrow_back
        </p-icon>{{ $t('core.app.back') }}
      </p-button>
      <il-shop-article-detail
        :value="{ article: { id: articleId } }"
      ></il-shop-article-detail>
    </div>
  </div>
</template>

<script lang="ts">
  import IlShopArticleDetail from '@/src/components/shop/il-shop-article-detail.vue';
  import Vue from 'vue';

  export default Vue.extend({
    components: {
      IlShopArticleDetail,
    },
    layout: 'layout-widget-shop-default',
    computed: {
      articleId() {
        return this.$config.getQueryOrValue('shop-articleid') || this.$route.query.article;
      },
    },
    mounted() {
      this.$routerUtils.updateQueryParams({ articleid: this.articleId }, { preserve: true });
    },
    methods: {
      goBack() {
        this.$router.back();
        // Due to a bug the "_startLocation" in the router history is not set when loaded as a widget
        // Check to see if the back actually worked and if not just open the main page
        if (!this.$router.history.pending) {
          this.$router.replace('/_widget/shop/article-list');
        }
      },
    },
  });
</script>

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WidgetEventModel from '../../model/widget-event-model';
import WidgetEventDTO from '../dto/widget-event-dto';
import dtoSchema from '../schemas/widget-event-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import EventOccurenceModel from '../../model/event-occurence-model';

export default abstract class WidgetEventModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WidgetEventDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.certificate = data.certificate ?? undefined;
      this.place = data.place ?? undefined;
      this.eventArea = data.eventArea ?? undefined;
      this.comment = data.comment ?? undefined;
      this.contactEmail = data.contactEmail ?? undefined;
      this.contactPerson = data.contactPerson ?? undefined;
      this.subtitle = data.subtitle ?? undefined;
      this.costs = data.costs ?? undefined;
      this.description = data.description ?? undefined;
      this.documentation = data.documentation ?? undefined;
      this.preparation = data.preparation ?? undefined;
      this.registrationDeadline = data.registrationDeadline ?? undefined;
      this.cancellation = data.cancellation ?? undefined;
      this.workingHours = data.workingHours ?? undefined;
      this.catering = data.catering ?? undefined;
      this.travelExpenses = data.travelExpenses ?? undefined;
      this.duration = data.duration ?? undefined;
      this.dates = data.dates ?? undefined;
      this.durationDays = data.durationDays;
      this.durationHourTarget = data.durationHourTarget;
      this.eventNr = data.eventNr;
      this.intendedDate = data.intendedDate ?? undefined;
      this.openDate = data.openDate ?? undefined;
      this.leader = data.leader ?? undefined;
      this.meetingPoint = data.meetingPoint ?? undefined;
      this.notes = data.notes ?? undefined;
      this.administration = data.administration ?? undefined;
      this.organisation = data.organisation ?? undefined;
      this.remarks = data.remarks ?? undefined;
      this.costsPerParticipant = data.costsPerParticipant;
      this.participationCosts = data.participationCosts;
      this.eventManager = data.eventManager ?? undefined;
      this.previousKnowledge = data.previousKnowledge ?? undefined;
      this.requirements = data.requirements ?? undefined;
      this.schedule = data.schedule ?? undefined;
      this.targetAudience = data.targetAudience ?? undefined;
      this.eventGoals = data.eventGoals ?? undefined;
      this.teachingAid = data.teachingAid ?? undefined;
      this.teachingMethod = data.teachingMethod ?? undefined;
      this.eventTeachingMethod = data.eventTeachingMethod ?? undefined;
      this.times = data.times ?? undefined;
      this.name = data.name ?? undefined;
      this.abbreviation = data.abbreviation ?? undefined;
      this.niveaus = data.niveaus ?? undefined;
      this.categories = data.categories ?? undefined;
      this.startDate = data.startDate ?? undefined;
      this.endDate = data.endDate ?? undefined;
      this.imageUrl = data.imageUrl ?? undefined;
      this.imageThumbUrl = data.imageThumbUrl ?? undefined;
      this.price = data.price ?? undefined;
      this.registrationStatus = data.registrationStatus ?? undefined;
      this.registrationIndicator = data.registrationIndicator ?? undefined;
      this.isOnlineRegistrationActive = data.isOnlineRegistrationActive;
      this.detailUrl = data.detailUrl ?? undefined;
      this.eventType = data.eventType ?? undefined;
      this.registrationFormUrl = data.registrationFormUrl ?? undefined;
      this.eventInformationDocumentUrl = data.eventInformationDocumentUrl ?? undefined;
      this.eventDocumentsUrl = data.eventDocumentsUrl ?? undefined;
      this.locationMapUrl = data.locationMapUrl ?? undefined;
      this.occurences = data.occurences?.map((i) => EventOccurenceModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  certificate?: string;
  /**
  */
  place?: string;
  /**
  */
  eventArea?: string;
  /**
  */
  comment?: string;
  /**
  */
  contactEmail?: string;
  /**
  */
  contactPerson?: string;
  /**
  */
  subtitle?: string;
  /**
  */
  costs?: string;
  /**
  */
  description?: string;
  /**
  */
  documentation?: string;
  /**
  */
  preparation?: string;
  /**
  */
  registrationDeadline?: string;
  /**
  */
  cancellation?: string;
  /**
  */
  workingHours?: string;
  /**
  */
  catering?: string;
  /**
  */
  travelExpenses?: string;
  /**
  */
  duration?: string;
  /**
  */
  dates?: string;
  /**
  * @type {int32}
  */
  durationDays?: number;
  /**
  * @type {int32}
  */
  durationHourTarget?: number;
  /**
  * @type {int64}
  */
  eventNr?: number;
  /**
  * @type {date-time}
  */
  intendedDate?: string;
  /**
  * @type {date-time}
  */
  openDate?: string;
  /**
  */
  leader?: string;
  /**
  */
  meetingPoint?: string;
  /**
  */
  notes?: string;
  /**
  */
  administration?: string;
  /**
  */
  organisation?: string;
  /**
  */
  remarks?: string;
  /**
  * @type {double}
  */
  costsPerParticipant?: number;
  /**
  * @type {double}
  */
  participationCosts?: number;
  /**
  */
  eventManager?: string;
  /**
  */
  previousKnowledge?: string;
  /**
  */
  requirements?: string;
  /**
  */
  schedule?: string;
  /**
  */
  targetAudience?: string;
  /**
  */
  eventGoals?: string;
  /**
  */
  teachingAid?: string;
  /**
  */
  teachingMethod?: string;
  /**
  */
  eventTeachingMethod?: string;
  /**
  */
  times?: string;
  /**
  */
  name?: string;
  /**
  */
  abbreviation?: string;
  /**
  */
  niveaus?: string[];
  /**
  */
  categories?: string[];
  /**
  * @type {date-time}
  */
  startDate?: string;
  /**
  * @type {date-time}
  */
  endDate?: string;
  /**
  */
  imageUrl?: string;
  /**
  */
  imageThumbUrl?: string;
  /**
  * @type {double}
  */
  price?: number;
  /**
  */
  registrationStatus?: string;
  /**
  */
  registrationIndicator?: string;
  /**
  */
  isOnlineRegistrationActive?: boolean;
  /**
  */
  detailUrl?: string;
  /**
  * @type {int64}
  */
  eventType?: number;
  /**
  */
  registrationFormUrl?: string;
  /**
  */
  eventInformationDocumentUrl?: string;
  /**
  */
  eventDocumentsUrl?: string;
  /**
  */
  locationMapUrl?: string;
  /**
  */
  occurences?: EventOccurenceModel[];

  static toModel(dto: DeepPartial<WidgetEventDTO>): WidgetEventModel;
  static toModel(dto: DeepPartial<WidgetEventDTO> | undefined | null): WidgetEventModel | undefined;
  static toModel(dto: DeepPartial<WidgetEventDTO> | undefined | null): WidgetEventModel | undefined {
    return dto ? new WidgetEventModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WidgetEventModel> | WidgetEventModel): WidgetEventDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      certificate: unwrapped.certificate,
      place: unwrapped.place,
      eventArea: unwrapped.eventArea,
      comment: unwrapped.comment,
      contactEmail: unwrapped.contactEmail,
      contactPerson: unwrapped.contactPerson,
      subtitle: unwrapped.subtitle,
      costs: unwrapped.costs,
      description: unwrapped.description,
      documentation: unwrapped.documentation,
      preparation: unwrapped.preparation,
      registrationDeadline: unwrapped.registrationDeadline,
      cancellation: unwrapped.cancellation,
      workingHours: unwrapped.workingHours,
      catering: unwrapped.catering,
      travelExpenses: unwrapped.travelExpenses,
      duration: unwrapped.duration,
      dates: unwrapped.dates,
      durationDays: unwrapped.durationDays,
      durationHourTarget: unwrapped.durationHourTarget,
      eventNr: unwrapped.eventNr,
      intendedDate: unwrapped.intendedDate,
      openDate: unwrapped.openDate,
      leader: unwrapped.leader,
      meetingPoint: unwrapped.meetingPoint,
      notes: unwrapped.notes,
      administration: unwrapped.administration,
      organisation: unwrapped.organisation,
      remarks: unwrapped.remarks,
      costsPerParticipant: unwrapped.costsPerParticipant,
      participationCosts: unwrapped.participationCosts,
      eventManager: unwrapped.eventManager,
      previousKnowledge: unwrapped.previousKnowledge,
      requirements: unwrapped.requirements,
      schedule: unwrapped.schedule,
      targetAudience: unwrapped.targetAudience,
      eventGoals: unwrapped.eventGoals,
      teachingAid: unwrapped.teachingAid,
      teachingMethod: unwrapped.teachingMethod,
      eventTeachingMethod: unwrapped.eventTeachingMethod,
      times: unwrapped.times,
      name: unwrapped.name,
      abbreviation: unwrapped.abbreviation,
      niveaus: unwrapped.niveaus,
      categories: unwrapped.categories,
      startDate: unwrapped.startDate,
      endDate: unwrapped.endDate,
      imageUrl: unwrapped.imageUrl,
      imageThumbUrl: unwrapped.imageThumbUrl,
      price: unwrapped.price,
      registrationStatus: unwrapped.registrationStatus,
      registrationIndicator: unwrapped.registrationIndicator,
      isOnlineRegistrationActive: unwrapped.isOnlineRegistrationActive,
      detailUrl: unwrapped.detailUrl,
      eventType: unwrapped.eventType,
      registrationFormUrl: unwrapped.registrationFormUrl,
      eventInformationDocumentUrl: unwrapped.eventInformationDocumentUrl,
      eventDocumentsUrl: unwrapped.eventDocumentsUrl,
      locationMapUrl: unwrapped.locationMapUrl,
      occurences: unwrapped.occurences,
    } as WidgetEventDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WidgetEventModel, can not map to WidgetEventDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

<template>
  <div class="shopCheckoutOverviewPage">
    <div class="sectionAddressInfo">
      <p-row class="sectionAddressInfo">
        <p-col class="addressInvoiceWrapper">
          <div class="title">
            {{ $t('shop.address.addressInvoice') }}
          </div>
          <p-row class="addressInvoice">
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.company') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.company }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.firstname') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.firstname }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.lastname') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.lastname }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.street') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.street }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.poBox') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.poBox }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.postcode') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.postCode }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.city') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.city }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.telephone') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.telephone }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.fax') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.fax }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.email') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ addressInvoice.email }}</div>
            </p-col>
          </p-row>
        </p-col>
        <p-col class="addressShippingWrapper">
          <div class="title">
            {{ $t('shop.address.addressShipping') }}
          </div>
          <p-row class="addressShipping">
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.company') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.company }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.firstname') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.firstname }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.lastname') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.lastname }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.street') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.street }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.poBox') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.poBox }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.postcode') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.postCode }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.city') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.city }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.telephone') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.telephone }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.fax') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.fax }}</div>
            </p-col>
            <p-col xs6 class="label">
              <div>{{ $t('shop.address.email') }}</div>
            </p-col>
            <p-col xs6 class="value">
              <div>{{ computedAddressShipping.email }}</div>
            </p-col>
          </p-row>
        </p-col>
        <p-col class="clientRemark">
          <div class="title">
            {{ $t('shop.order.clientRemark') }}
          </div>
          <div>{{ clientRemark }}</div>
        </p-col>
      </p-row>
      <div class="sectionArticles">
        <div class="title">
          {{ $t('shop.article.articles') }}
        </div>
        <il-shop-article-list v-model="articles" class="checkoutArticles" :disabled="true" :compact="true"></il-shop-article-list>
      </div>
    </div>
    <div class="shoppingBasketCosts">
      <il-shop-shopping-basket-costs :show-different-prices="false" :can-choose-membership="false" :show-vat-details="true">
        <template slot="prepend-actions">
          <p-checkbox v-model="hasAcceptedConditions" label="slot">
            <a target="_blank" rel="noopener noreferrer" :href="$config.values['shop-agburl']">{{ $t('shop.acceptConditions') }}</a>
          </p-checkbox>
        </template>
        <template #action-continue="{ disabled }">
          <p-button
            :loading="isLoading"
            :disabled="!hasAcceptedConditions || isLoading || disabled"
            class="checkoutConfirmButton"
            @click="sendOrder"
          >
            {{ $t('shop.shoppingBasket.checkoutConfirm') }}
          </p-button>
        </template>
        <template slot="append-actions">
          <p-request-alert v-model="errorResponse"></p-request-alert>
        </template>
      </il-shop-shopping-basket-costs>
    </div>
    <p-root-portal>
      <p-dialog v-if="isLoading" class="checkoutSubmissionDialog" :title="$t('core.app.loading')" :has-close-button="false">
        <template #content>
          <p-row>
            <span class="beingSubmittedText">{{ $t('shop.order.isBeingSent') }}</span>
            <p-progress-linear indeterminate></p-progress-linear>
          </p-row>
        </template>
      </p-dialog>
    </p-root-portal>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import IlShopArticleList from '@/src/components/shop/il-shop-article-list.vue';
  import IlShopShoppingBasketCosts from '@/src/components/shop/il-shop-shopping-basket-costs.vue';
  import shoppingBasket from '../../../../utilities/shoppingBasket';
  import BasketItemModel from '../../../../services/v2/model/basket-item-model';
  import AddressModel from '../../../../services/v2/model/address-model';

  export default Vue.extend({
    components: {
      IlShopArticleList,
      IlShopShoppingBasketCosts,
    },
    layout: 'layout-widget-shop-reduced',
    data: () => ({
      errorResponse: undefined,
      hasAcceptedConditions: false,
      isLoading: false,
      articles: [] as BasketItemModel[],
      addressInvoice: new AddressModel({} as any),
      addressShipping: new AddressModel({} as any),
      isShippingSameAsInvoice: true,
      clientRemark: '',
    }),
    computed: {
      computedAddressShipping() {
        return this.isShippingSameAsInvoice
          ? this.addressInvoice
          : this.addressShipping;
      },
    },
    async mounted() {
      this.$routerUtils.updateQueryParams({ page: 'checkout-overview' }, { preserve: true });
      this.errorResponse = undefined;
      this.articles = shoppingBasket.getLocalBasket().basketItems ?? [];
      try {
        await shoppingBasket.updateBasketFromServer();
        const { basketItems } = shoppingBasket.getLocalBasket();
        this.articles = basketItems?.map((item: any) => item.article) ?? [];
      } catch (error: any) {
        this.errorResponse = error;
        this.$log.error(error);
      }
      this.addressInvoice = shoppingBasket.getInvoiceAddress()!;
      this.addressShipping = shoppingBasket.getShippingAddress()!;
      this.isShippingSameAsInvoice = shoppingBasket.getIsShippingSameAsInvoice()!;
      this.clientRemark = shoppingBasket.getClientRemark()!;
    },
    methods: {
      async sendOrder() {
        this.errorResponse = undefined;
        this.isLoading = true;
        try {
          await shoppingBasket.sendOrder();
          shoppingBasket.clear();
          this.$router.push({
            path: '/_widget/shop/thankyou',
          });
        } catch (error: any) {
          this.$log.error(error);
          this.errorResponse = error;
        }
        this.isLoading = false;
      },
    },
  });
</script>

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/event-occurence-schema',
  type: 'object',
  required: ['id'],
  nullable: true,
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    startDate: {
      type: 'string',
      format: 'date-time',
    },
    endDate: {
      type: 'string',
      format: 'date-time',
    },
    eventExecution: {
      enum: [
        0,
        1,
        2,
        3,
      ],
    },
    room: {
      type: 'string',
      nullable: true,
    },
    company: {
      type: 'string',
      nullable: true,
    },
    street: {
      type: 'string',
      nullable: true,
    },
    city: {
      type: 'string',
      nullable: true,
    },
    postCode: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;

<template>
  <div class="shopArticleListPage">
    <p-request-alert v-model="errorResponse" class="error"></p-request-alert>
    <div v-if="isInSearchMode" class="searchResults">
      <p-icon left>
        search
      </p-icon>
      <strong>({{ isLoading ? '…' : pagination.totalCount }}) {{ $t('shop.toolbar.searchResults') }}:</strong>
    </div>
    <p-progress-linear v-if="isLoading" indeterminate></p-progress-linear>
    <il-shop-article-list v-model="articles">
      <template #article-action="{ article }">
        <il-shop-article-list-actions :value="article"></il-shop-article-list-actions>
      </template>
    </il-shop-article-list>
    <div class="pagingationWrapper">
      <p-pagination
        v-model="pagination"
        class="pagination"
        no-paging-size
        @input="onPaginationChange"
      ></p-pagination>
    </div>
  </div>
</template>

<script lang="ts">
  import _ from '@glittr/frontend-core/src/utils';
  import Vue from 'vue';
  import GetArticlesListRequestModel from '@/src/services/v2/model/get-articles-list-request-model';
  import PaginationDataModel from '@/src/services/v2/model/pagination-data-model';
  import ArticleModel from '@/src/services/v2/model/article-model';
  import IlShopArticleList from '@/src/components/shop/il-shop-article-list.vue';
  import IlShopArticleListActions from '@/src/components/shop/il-shop-article-list-actions.vue';

  export default Vue.extend({
    components: {
      IlShopArticleList,
      IlShopArticleListActions,
    },
    layout: 'layout-widget-shop-default',
    data: () => ({
      pagination: new PaginationDataModel({
        currentPage: 1,
        pageSize: 10,
        totalCount: 0,
        totalPages: 1,
      }),
      searchTerm: undefined as string | undefined,
      articles: [] as ArticleModel[],
      errorResponse: undefined,
      isLoading: false,
    }),
    computed: {
      isInSearchMode(): boolean {
        return !!this.searchTerm;
      },
      categoryId(): string {
        return this.$config.getQueryOrValue('shop-categoryid') || this.$route.query.category as string;
      },
      searchQuery(): string {
        return this.$config.getQueryOrValue('shop-articlenumber') || this.$route.query.search as string;
      },
    },
    watch: {
      '$route.query': {
        handler() {
          this.populateArticles();
        },
      },
    },
    mounted() {
      this.searchTerm = this.searchQuery as string;
      this.updateQueryParams();
      this.populateArticles(this.searchTerm);
      this.$eventbus.on('search', this.onSearch);
    },
    beforeDestroy() {
      this.$eventbus.off('search', this.onSearch);
    },
    methods: {
      updateQueryParams() {
        this.$routerUtils.updateQueryParams({
          page: undefined, categoryid: this.categoryId, articlenumber: this.searchTerm, articleid: undefined,
        }, { preserve: true });
      },
      onPaginationChange() {
        this.populateArticles(this.searchTerm);
      },
      onSearch(searchTerm: string) {
        this.$debounce(() => {
          this.searchTerm = _.isEmpty(searchTerm) ? undefined : searchTerm;
          this.updateQueryParams();
          this.populateArticles(searchTerm);
        }, 500, this)();
      },
      async populateArticles(searchTerm?: string) {
        this.errorResponse = undefined;
        this.articles = [];
        const categoryId = searchTerm ? undefined : this.categoryId;
        this.isLoading = true;
        try {
          const req = new GetArticlesListRequestModel({
            searchTerm,
            categoryId,
            'paging.CurrentPage': this.pagination.currentPage,
            'paging.PageSize': this.pagination.pageSize,
            'paging.IncludeCount': true,
          });
          const response = await this.$service.v2.api.shop.getArticlesList(req);
          if (response) {
            this.pagination = response.paginationData!;
            this.articles = response.items?.map((i) => i.data) ?? [];
          }
        } catch (error: any) {
          this.errorResponse = error;
          this.$log.error(error);
        }
        this.isLoading = false;
      },
    },
  });
</script>

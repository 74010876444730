// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AddressModel from '../../model/address-model';
import AddressDTO from '../dto/address-dto';
import dtoSchema from '../schemas/address-schema';

export default abstract class AddressModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AddressDTO>) {
    super();
    if (data) {
      this.addressType = data.addressType;
      this.city = data.city ?? undefined;
      this.company = data.company ?? undefined;
      this.email = data.email ?? undefined;
      this.fax = data.fax ?? undefined;
      this.firstname = data.firstname ?? undefined;
      this.lastname = data.lastname ?? undefined;
      this.sex = data.sex ?? undefined;
      this.poBox = data.poBox ?? undefined;
      this.postCode = data.postCode ?? undefined;
      this.street = data.street ?? undefined;
      this.telephone = data.telephone ?? undefined;
    }
  }
  /**
  * @type {int32}
  */
  addressType?: 0 | 1; // AddressTypeEnumDTO
  /**
  */
  city?: string;
  /**
  */
  company?: string;
  /**
  */
  email?: string;
  /**
  */
  fax?: string;
  /**
  */
  firstname?: string;
  /**
  */
  lastname?: string;
  /**
  */
  sex?: string;
  /**
  */
  poBox?: string;
  /**
  */
  postCode?: string;
  /**
  */
  street?: string;
  /**
  */
  telephone?: string;

  static toModel(dto: DeepPartial<AddressDTO>): AddressModel;
  static toModel(dto: DeepPartial<AddressDTO> | undefined | null): AddressModel | undefined;
  static toModel(dto: DeepPartial<AddressDTO> | undefined | null): AddressModel | undefined {
    return dto ? new AddressModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AddressModel> | AddressModel): AddressDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      addressType: unwrapped.addressType,
      city: unwrapped.city,
      company: unwrapped.company,
      email: unwrapped.email,
      fax: unwrapped.fax,
      firstname: unwrapped.firstname,
      lastname: unwrapped.lastname,
      sex: unwrapped.sex,
      poBox: unwrapped.poBox,
      postCode: unwrapped.postCode,
      street: unwrapped.street,
      telephone: unwrapped.telephone,
    } as AddressDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AddressModel, can not map to AddressDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

<template>
  <div class="shopRegisterPage">
    <div class="registerWrapper">
      <div class="toolbar">
        <h2>{{ $t('shop.login.registration') }}</h2>
      </div>
      <div class="registerForm">
        <il-shop-form-address :show-title="false" :can-set-delivery-address="false" @valid="onValid"></il-shop-form-address>
      </div>
      <p-request-alert v-model="errorResponse"></p-request-alert>
      <div class="sectionRegisterActions">
        <p-button :disabled="!isFormValid || isLoading" :loading="isLoading" text @click="performRegistration">
          {{ $t('shop.login.register') }}
        </p-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import RegisterForShopRequestModel from '@/src/services/v2/model/register-for-shop-request-model';
  import IlShopFormAddress from '@/src/components/shop/il-shop-form-address.vue';
  import shoppingBasket from '../../../../utilities/shoppingBasket';

  export default Vue.extend({
    components: {
      IlShopFormAddress,
    },
    layout: 'layout-widget-shop-reduced',
    data: () => ({
      login: {},
      register: {},
      isLoading: false,
      isFormValid: false,
      errorResponse: undefined as any,
    }),
    mounted() {
      this.$routerUtils.updateQueryParams({ page: 'register' }, { preserve: true });
    },
    methods: {
      onValid(isFormValid: boolean) {
        this.$set(this, 'isFormValid', isFormValid);
      },
      async performRegistration() {
        this.isLoading = true;
        try {
          const userData = shoppingBasket.getInvoiceAddress();
          const request = new RegisterForShopRequestModel({ ...userData! });
          const response = await this.$service.v2.api.shop.registerForShop(request);
          shoppingBasket.setAccount(response.data);
          (response.data.addresses ?? []).forEach((address) => {
            if (address.addressType === 0) {
              shoppingBasket.setInvoiceAddress(address);
            }
          });
          this.$router.replace('/_widget/shop/register-success');
        } catch (error: any) {
          this.errorResponse = error;
          this.$log.error(error);
        }
        this.isLoading = false;
      },
    },
  });
</script>

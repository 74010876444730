// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ShippingInfoSchema from './shipping-info-schema';
import VatSummarySchema from './vat-summary-schema';

const schema = {
  $id: '/basket-summary-schema',
  type: 'object',
  required: ['netAmount', 'vatAmount', 'grossAmount', 'grandTotalAmount', 'totalWeight', 'totalQuantity'],
  nullable: true,
  properties: {
    priceType: {
      type: 'string',
      nullable: true,
    },
    netAmount: {
      type: 'number',
    },
    vatAmount: {
      type: 'number',
    },
    grossAmount: {
      type: 'number',
    },
    shippingInfo: {
      ...ShippingInfoSchema,
    },
    grandTotalAmount: {
      type: 'number',
    },
    totalWeight: {
      type: 'number',
    },
    totalQuantity: {
      type: 'integer',
    },
    vatSummaryItems: {
      type: 'array',
      nullable: true,
      items: { ...VatSummarySchema },
    },
  },
} as IJSONSchema;

export default schema;

<template>
  <div class="ilShopArticleListEntry">
    <il-shop-article-image
      v-if="!compact"
      :width="getImageWidth()"
      :height="getImageHeight()"
      :title="imageTitle"
      :src="shoppingBasket.getPreviewArticleImageUrl(articleData)"
      @click="gotoDetail"
    ></il-shop-article-image>
    <template v-if="computedLoading">
      <p-progress-linear class="loadingIndicator" indeterminate></p-progress-linear>
    </template>
    <div v-else class="articleEntryWrapper">
      <div class="sectionArticleInfo">
        <h4 class="articleTitle">
          {{ articleTitle }}
        </h4>
        <template v-if="$media.isNotMobile && !compact">
          <p class="articleDescription" v-html="articleDescription"></p>
        </template>
      </div>
      <div class="sectionArticlePricing">
        <div v-if="articleData" class="articlePrices">
          <div v-if="memberPrice" class="memberPriceWrapper">
            <span class="priceLabel member">{{ $t('shop.price.member') }}:</span>
            <span class="price member">{{ $format.currency(memberPrice.netPrice, 'CHF') }}</span>
          </div>
          <div v-if="standardPrice" class="standardPriceWrapper">
            <span class="priceLabel standard">{{ $t('shop.price.standard') }}:</span>
            <span class="price standard">{{ $format.currency(standardPrice.netPrice, 'CHF') }}</span>
          </div>
        </div>
      </div>
      <div class="sectionArticleActions">
        <slot :article="articleData"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import _ from '@glittr/frontend-core/src/utils';
  import Vue from 'vue';
  import IlShopArticleImage from './il-shop-article-image.vue';
  import shoppingBasket from '../../utilities/shoppingBasket';

  export default Vue.extend({
    name: 'IlSopArticleListEntry',
    components: {
      IlShopArticleImage,
    },
    props: {
      value: { type: Object, default: undefined },
      loading: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      compact: { type: Boolean, default: false },
    },
    data: () => ({
      internalLoading: false,
      shoppingBasket,
    }),
    computed: {
      computedDisabled(): boolean {
        return this.disabled || this.articleData.error;
      },
      imageTitle(): string {
        if (!this.articleData || !this.articleData.title) {
          return '-';
        }
        const title = this.articleData.title + this.articleData.abbreviation;
        if (title.match(/[A-Z][A-Z]:/g)) {
          return title.substring(4);
        }
        return title;
      },
      articleTitle(): string {
        if (_.isEmpty(this.articleData) || !this.articleData.title) {
          return `${this.$t('shop.article.loading')}…`;
        }
        return this.$format.ellipsis(this.articleData.title, 75) ?? '';
      },
      articleDescription(): string {
        if (!this.articleData || !this.articleData.description) {
          return `${this.$t('shop.article.loading')}…`;
        }
        const articleAbbreviationText = `<span class="caption-2">${this.$t('shop.article.abbreviation')}: ${this.articleData.abbreviation}</span><br/><br/>`;
        const description = articleAbbreviationText + this.articleData.description;
        return this.$format.ellipsis(description, 125) ?? '';
      },
      memberPrice(): number | undefined {
        if (_.isEmpty(this.articleData)) {
          return undefined;
        }
        if (_.isNil(this.articleData.prices)) {
          return undefined;
        }
        return this.articleData.prices.find((price: any) => price.priceType === 'member');
      },
      standardPrice(): number | undefined {
        if (_.isEmpty(this.articleData)) {
          return undefined;
        }
        if (_.isNil(this.articleData.prices)) {
          return undefined;
        }
        return this.articleData.prices.find((price: any) => price.priceType === 'standard');
      },
      computedLoading(): boolean {
        return this.loading || this.internalLoading;
      },
      articleData(): any {
        return this.value ?? {};
      },
    },
    methods: {
      gotoDetail() {
        this.$router.push(`/_widget/shop/article-detail?articleid=${this.articleData.id}`);
      },
      getImageWidth(): number {
        return this.$media.isMobile ? 100 : 170;
      },
      getImageHeight(): number {
        return this.$media.isMobile ? 141 : 241;
      },
    },
  });
</script>

import Vue from 'vue';
import EventOccurenceModelBase from '../_generated/modelBases/event-occurence-model-base';

export default class EventOccurenceModel extends EventOccurenceModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs

  // 21.03.2022 09:15 - 16:45
  get terminString():string {
    let output = '';
    if (this.startDate) {
      output = Vue.$format.localDateAndTime(this.startDate)!;
    }
    if (this.endDate) {
      output += ` - ${Vue.$format.localTime(this.endDate)!}`;
    }
    return output;
  }

  // 09:15 - 16:45
  get timeString():string {
    let output = '';
    if (this.startDate) {
      output = Vue.$format.localTime(this.startDate)!;
    }
    if (this.endDate) {
      output += ` - ${Vue.$format.localTime(this.endDate)!}`;
    }
    return output;
  }
}

<template>
  <div class="shopRegisterSuccessPage">
    <div class="registerSuccessWrapper">
      <div class="toolbar">
        <h2>{{ $t('shop.login.registerSuccess') }}</h2>
      </div>
      <p-progress-linear v-model="progress" height="2"></p-progress-linear>
      <div class="registerSuccess">
        <p-icon class="registerSuccessIcon">
          check
        </p-icon>
        <span class="registerSuccessText" v-html="$t('shop.login.registerSuccessDescription')"></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    layout: 'layout-widget-shop-empty',
    data: () => ({
      elapsedWait: 0,
      progress: 0,
      intervalHandel: 0,
    }),
    beforeDestroy() {
      clearInterval(this.intervalHandel);
    },
    mounted() {
      this.$routerUtils.updateQueryParams({ page: 'login' }, { preserve: true });
      const waitTime = 7500;
      const interval = 1000;
      this.intervalHandel = setInterval(() => {
        this.elapsedWait += interval;
        this.progress = (this.elapsedWait / waitTime) * 100.0;
        if (this.progress >= 100) {
          setTimeout(() => {
            clearInterval(this.intervalHandel);
            // For development purposes, don't redirect to the next page
            if (!this.$router.currentRoute.query.noRedirect) {
              this.$router.replace('/_widget/shop/checkout-address', () => {});
            }
          }, 1000);
        }
      }, interval);
    },
  });
</script>

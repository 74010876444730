<template>
  <div></div>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    beforeMount() {
      // Get values from the query or config/attributes or if those aren't found keep the functionality of the original shop
      const articleNumber = this.$config.getQueryOrValue('shop-articlenumber') || this.$route.query.article;
      const categoryId = this.$config.getQueryOrValue('shop-categoryid') || this.$route.query.category;
      const articleId = this.$config.getQueryOrValue('shop-articleid') || this.$route.query.article;
      let { page } = this.$routerUtils.getQueryParams();
      if (articleId !== undefined) { page = 'article-detail'; }
      this.$router.replace({
        path: `/_widget/shop/${page ?? 'article-list'}`,
        query: {
          search: articleNumber,
          categoryid: categoryId,
          articleid: articleId,
        },
      });
    },
  });
</script>

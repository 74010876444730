// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import BasketItemSchema from './basket-item-schema';
import AddressSchema from './address-schema';
import BasketSummarySchema from './basket-summary-schema';

const schema = {
  $id: '/basket-schema',
  type: 'object',
  required: ['basketItems'],
  properties: {
    id: {
      type: 'integer',
      nullable: true,
    },
    basketItems: {
      type: 'array',
      items: { ...BasketItemSchema },
    },
    addresses: {
      type: 'array',
      nullable: true,
      items: { ...AddressSchema },
    },
    isShippingSameAsInvoice: {
      type: 'boolean',
    },
    summary: {
      type: 'array',
      nullable: true,
      items: { ...BasketSummarySchema },
    },
  },
} as IJSONSchema;

export default schema;

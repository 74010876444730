<template>
  <figure class="widget-event-card item" :data-id="value.id">
    <div class="image">
      <div v-if="!value.imageUrl" class="backgroundimage" :style="{ 'background-image': `url(/Portals/0/Inhalte/_system/widget-standard-angebot.png)` }"></div>
      <div v-if="value.imageUrl" class="backgroundimage" :style="{ 'background-image': `url(${value.imageUrl})` }"></div>
    </div>
    <div class="text_wrapper">
      <div tabindex="0" class="row" @click.stop="gotoDetail" @keyup.enter="gotoDetail">
        <div class="col-12">
          <figcaption class="titel">
            {{ value.name }}
          </figcaption>
        </div>
      </div>
      <div class="row">
        <div class="col-8 col-lg-9" @click.stop="gotoDetail" @keyup.enter="gotoDetail">
          <div class="row">
            <div class="col-12 col-lg-7">
              <div class="datum">
                {{ dateSectionText }}
              </div>
              <div class="preis">
                {{ $t('widget.events.costs') }}: {{ value.priceString }}
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div class="ort">
                {{ value.eventArea }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 statuswrapper">
              <div class="status">
                <div :class="`circle ${value.registrationIndicator} ${value.registrationStatus}`"></div>
                <div class="availability">
                  {{ value.availabilityString }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-3">
          <div class="detaillinkwrapper">
            <a
              class="detail"
              href="javascript:void(0)"
              @click.stop="gotoDetail"
              @keyup.enter.prevent="gotoDetail"
              @keyup.space.prevent="gotoDetail"
            >{{ $t('widget.events.detail') }}</a>
          </div>
          <a
            v-if="value.hasAppointments"
            class="termine toggle"
            href="javascript:void(0)"
            @click.stop="toggleAppointments"
            @keyup.enter.stop="toggleAppointments"
            @keyup.space.stop.prevent="toggleAppointments"
          >{{ $t('widget.events.appointments') }}</a>
          <a
            v-if="value.isBookingActionAvailable && isRegistrationExternalLink"
            class="ghostbutton anmelden"
            target="_blank"
            :href="registrationLink"
          >
            {{ $t('widget.events.register') }}
          </a>
          <a
            v-if="value.isBookingActionAvailable && !isRegistrationExternalLink"
            class="ghostbutton anmelden"
            href="javascript:void(0)"
            @click.stop="gotoRegister"
            @keyup.enter.prevent="gotoRegister"
            @keyup.space.prevent="gotoRegister"
          >
            {{ $t('widget.events.register') }}
          </a>
        </div>
        <div v-if="isAppointmentsVisible && value.hasAppointments" class="terminliste col-12">
          <div class="row">
            <div class="col-8 col-lg-9">
              {{ $t('widget.events.courseData') }}
            </div>
            <div class="col-4 col-lg-3">
              <a
                class="termine toggle"
                @click.stop="toggleAppointments"
                @keyup.enter.prevent="toggleAppointments"
                @keyup.space.prevent="toggleAppointments"
              >{{ $t('widget.events.close') }}</a>
            </div>
          </div>
          <ul>
            <li v-for="t in value.occurences" :key="t.id">
              {{ t.terminString }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </figure>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import WidgetEventListModel from '../services/v2/model/widget-event-list-model';
  import widgetEventUtilities from '../utilities/widgets/WidgetEventUtilities';

  export default Vue.extend({
    name: 'WidgetEventCard',
    props: {
      value: { type: Object as PropType<WidgetEventListModel>, default: undefined },
    },
    data: () => ({
      isAppointmentsVisible: false,
    }),
    computed: {
      dateSectionText(): string {
        const startDate = this.$format.localDate(this.value.startDate!);
        const endDate = this.$format.localDate(this.value.endDate!);
        const dateString = `${startDate} - ${endDate}`;

        const configText = dateString;
        const displayText = this.$tAlt(configText, configText);
        return displayText;
      },
      registrationLink(): string {
        return widgetEventUtilities.getEventRegisterUrl(this.value);
      },
      isRegistrationExternalLink(): boolean {
        return !!this.$routerUtils.isUrl(this.registrationLink);
      },
    },
    methods: {
      toggleAppointments() {
        this.isAppointmentsVisible = !this.isAppointmentsVisible;
      },
      gotoRegister() {
        this.$routerUtils.pushRouteOrUrl(widgetEventUtilities.getEventRegisterUrl(this.value));
      },
      gotoDetail() {
        if (this.value.useEventLinkAsDetailPage && this.value.detailUrl) {
          window.open(this.value.detailUrl);
          return;
        }
        this.$routerUtils.pushRouteOrUrl(widgetEventUtilities.getEventDetailUrl(this.value));
      },
    },
  });
</script>

<template>
  <div class="shopLoginPage">
    <p-row class="loginWrapper">
      <p-col class="loginSection">
        <div class="toolbar">
          <h2>{{ $t('shop.login.iAmAlreadyRegistered') }}</h2>
        </div>
        <div class="sectionLogin">
          <p-text-field
            v-model="login.username"
            autocomplete="username"
            :errors="loginMessage ? ' ' : undefined"
            context-icon="person"
            class="loginEmailField"
            :label="$t('shop.login.usernameOrPassword')"
          ></p-text-field>
          <p-text-field
            v-model="login.password"
            :errors="loginMessage"
            context-icon="lock"
            type="password"
            class="loginPasswordField"
            :label="$t('shop.login.password')"
            @keyup.enter.native="performLogin"
          ></p-text-field>
          <div class="loginActions">
            <p-button
              :disabled="_.isEmpty(login.username) || _.isEmpty(login.password) || loginLoading"
              :loading="loginLoading"
              class="loginButton"
              @click="performLogin"
            >
              {{ $t('shop.login.loginAction') }}
            </p-button>
            <div class="resetPassword">
              <a :href="$t('shop.login.requestNewPasswordLink')" target="_blank">{{ $t('shop.login.requestNewPassword') }}</a>
            </div>
          </div>
        </div>
      </p-col>
      <p-col class="registerSection">
        <div class="toolbar">
          <h2>{{ $t('shop.login.iAmNew') }}</h2>
        </div>
        <div class="sectionRegister">
          <div class="registerDescription" v-html="$t('shop.login.registerDescription')"></div>
          <il-shop-email-field
            ref="registerEmailField"
            v-model="registerEmail"
            class="registerEmailField"
            :errors="registerMessage"
            :valid.sync="isRegisterEmailValid"
            :label="$t('shop.login.email')"
            @keyup.enter.native="gotoRegister"
          ></il-shop-email-field>
          <div class="registerActions">
            <p-button
              :disabled="!isRegisterEmailValid || _.isEmpty(registerEmail) || registerLoading"
              :loading="registerLoading"
              class="registerButton"
              @click="gotoRegister"
            >
              {{ $t('shop.login.register') }}
            </p-button>
          </div>
        </div>
      </p-col>
    </p-row>
  </div>
</template>

<script lang="ts">
  import LoginForShopRequestModel from '@/src/services/v2/model/login-for-shop-request-model';
  import Vue from 'vue';
  import IsUsernameAvailableForShopRequestModel from '@/src/services/v2/model/is-username-available-for-shop-request-model';
  import AddressModel from '@/src/services/v2/model/address-model';
  import IlShopEmailField from '@/src/components/shop/fields/il-shop-email-field.vue';
  import shoppingBasket from '../../../../utilities/shoppingBasket';

  export default Vue.extend({
    components: {
      IlShopEmailField,
    },
    layout: 'layout-widget-shop-reduced',
    data: () => ({
      login: {
        username: undefined as string | undefined,
        password: undefined as string | undefined,
      },
      registerEmail: '',
      loginLoading: false,
      registerLoading: false,
      isRegisterEmailValid: false,
      loginMessage: undefined as string | undefined,
      registerMessage: undefined as string | undefined,
    }),
    watch: {
      login:
        {
          deep: true,
          handler() {
            this.loginMessage = undefined;
          },
        },
      registerEmail() {
        this.registerMessage = undefined;
        const newAddress = new AddressModel();
        newAddress.email = this.registerEmail;
        shoppingBasket.setInvoiceAddress(newAddress);
        // Reset all info as this is a fresh account
        shoppingBasket.setShippingAddress(new AddressModel());
        shoppingBasket.setIsShippingSameAsInvoice(true);
      },
    },
    mounted() {
      this.$routerUtils.updateQueryParams({ page: 'login' }, { preserve: true });
    },
    methods: {
      async gotoRegister() {
        this.registerLoading = true;
        try {
          const req = new IsUsernameAvailableForShopRequestModel();
          req.username = encodeURIComponent(this.registerEmail);
          const response = await this.$service.v2.api.shop.isUsernameAvailableForShop(req);
          if (response.data === true) {
            const emailField = this.$refs.registerEmailField as Vue;
            const el = emailField.$el as HTMLInputElement;
            el.blur();
            this.$nextTick(() => {
              if (this.isRegisterEmailValid) {
                this.$router.push('/_widget/shop/register');
              }
            });
          } else {
            this.registerMessage = this.$t('shop.login.accountAlreadyTaken');
          }
        } catch (error: any) {
          this.$log.error(error);
          this.registerMessage = error?.message;
        }
        this.registerLoading = false;
      },
      async performLogin() {
        this.loginLoading = true;
        try {
          const req = new LoginForShopRequestModel({ username: this.login.username!, password: this.login.password! });
          const response = await this.$service.v2.api.shop.loginForShop(req);
          shoppingBasket.setInvoiceAddress(new AddressModel());
          shoppingBasket.setAccount(response.data);
          (response.data.addresses ?? []).forEach((address) => {
            if (address.addressType === 0) {
              shoppingBasket.setInvoiceAddress(address);
            }
          });
          this.$router.push('/_widget/shop/checkout-address', () => {});
        } catch (error: any) {
          if (error.status === 401 || error.status === 403) {
            this.loginMessage = this.$t('shop.login.invalidLogin');
          } else {
            this.loginMessage = error.message;
          }
        }
        this.loginLoading = false;
      },
    },
  });
</script>

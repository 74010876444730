import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/swissmechanic/:workspace', // removed :tenant because of a collision with core route _widgets/events
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },

    // WIDGET TESTING __________________________________________________________
    {
      path: '/_admin/testing/widgets/events/widget-event-detail-test',
      name: 'admin-testing-widget-event-detail',
      component: () => import('./views/pages/_admin/testing/widgets/widget-event-detail-test.vue'),
    }, {
      path: '/_admin/testing/widgets/tocco-event-search',
      name: 'admin-testing-widgets-tocco-event-search',
      component: () => import('./views/pages/_admin/testing/widgets/tocco-event-search-testing.vue'),
    },
    {
      path: '/_admin/testing/widgets/tocco-event-next',
      name: 'admin-testing-widgets-tocco-event-next',
      component: () => import('./views/pages/_admin/testing/widgets/tocco-event-next-testing.vue'),
    },
    {
      path: '/_admin/testing/widgets/shop',
      name: 'admin-testing-widgets-shop',
      component: () => import('./views/pages/_admin/testing/widgets/widget-shop-testing.vue'),
    },
    {
      path: '/_admin/testing/widgets/shop-article-detail',
      name: 'admin-testing-widgets-shop-article-detail',
      component: () => import('./views/pages/_admin/testing/widgets/widget-shop-article-detail-testing.vue'),
    },

    // SHOP WIDGET ______________________________________________________
    {
      path: '/_widget/shop/article-detail',
      component: () => import('./views/pages/widgets/shop/article-detail.vue'),
      name: 'article-detail',
    },
    {
      path: '/_widget/shop/article-list',
      component: () => import('./views/pages/widgets/shop/article-list.vue'),
      name: 'article-list',
    },
    {
      path: '/_widget/shop/checkout-address',
      component: () => import('./views/pages/widgets/shop/checkout-address.vue'),
      name: 'checkout-address',
    },
    {
      path: '/_widget/shop/checkout-overview',
      component: () => import('./views/pages/widgets/shop/checkout-overview.vue'),
      name: 'checkout-overview',
    },
    {
      path: '/_widget/shop/login',
      component: () => import('./views/pages/widgets/shop/login.vue'),
      name: 'login',
    },
    {
      path: '/_widget/shop/register',
      component: () => import('./views/pages/widgets/shop/register.vue'),
      name: 'register',
    },
    {
      path: '/_widget/shop/register-success',
      component: () => import('./views/pages/widgets/shop/register-success.vue'),
      name: 'register-success',
    },
    {
      path: '/_widget/shop/shopping-basket',
      component: () => import('./views/pages/widgets/shop/shopping-basket.vue'),
      name: 'shopping-basket',
    },
    {
      path: '/_widget/shop/thankyou',
      component: () => import('./views/pages/widgets/shop/thankyou.vue'),
      name: 'thankyou',
    },
    {
      path: '/_widget/shop/',
      component: () => import('./views/pages/widgets/shop/index.vue'),
      name: 'index',
    },
    {
      path: '/_widget/event/widget-smc-event-detail',
      component: () => import('./views/pages/widgets/event/widget-smc-event-detail.vue'),
      name: 'details',
    },
    {
      path: '/_widget/event/smc-next-cards',
      component: () => import('./views/pages/widgets/event/widget-smc-event-list-card.vue'),
      name: 'smcNextCards',
    },
    {
      path: '/_widget/event/smc-next-list',
      component: () => import('./views/pages/widgets/event/widget-smc-event-list.vue'),
      name: 'smcNextList',
    },
    {
      path: '/_widget/event/smc-events',
      component: () => import('./views/pages/widgets/event/widget-smc-event-search.vue'),
      name: 'smcEventSearch',
    },
  ],
};

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CategoryListModel from '../../model/category-list-model';
import CategoryListDTO from '../dto/category-list-dto';
import dtoSchema from '../schemas/category-list-schema';
import CategoryParentReferenceModel from '../../model/category-parent-reference-model';

export default abstract class CategoryListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CategoryListDTO>) {
    super();
    if (data) {
      this.id = data.id ?? undefined;
      this.title = data.title ?? undefined;
      this.categories = data.categories?.map((i) => CategoryListModel.toModel(i)) ?? [];
      this.articles = data.articles ?? undefined;
      this.parent = data.parent ? CategoryParentReferenceModel.toModel(data.parent) : undefined;
      this.breadcrumb = data.breadcrumb?.map((i) => CategoryParentReferenceModel.toModel(i)) ?? [];
    }
  }
  /**
  */
  id?: string;
  /**
  */
  title?: string;
  /**
  */
  categories?: CategoryListModel[];
  /**
  */
  articles?: string[];
  /**
  */
  parent?: CategoryParentReferenceModel;
  /**
  */
  breadcrumb?: CategoryParentReferenceModel[];

  static toModel(dto: DeepPartial<CategoryListDTO>): CategoryListModel;
  static toModel(dto: DeepPartial<CategoryListDTO> | undefined | null): CategoryListModel | undefined;
  static toModel(dto: DeepPartial<CategoryListDTO> | undefined | null): CategoryListModel | undefined {
    return dto ? new CategoryListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CategoryListModel> | CategoryListModel): CategoryListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      title: unwrapped.title,
      categories: unwrapped.categories,
      articles: unwrapped.articles,
      parent: unwrapped.parent,
      breadcrumb: unwrapped.breadcrumb,
    } as CategoryListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CategoryListModel, can not map to CategoryListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

<template>
  <div class="shopShoppingBasketPage">
    <div class="shoppingBasketArticles">
      <p-request-alert v-model="errorResponse"></p-request-alert>
      <p-alert type="info" :value="!articles || articles.length === 0">
        {{ $t('shop.shoppingBasket.noArticles') }}
      </p-alert>
      <il-shop-article-list v-if="articles && articles.length > 0" v-model="articles" :disabled="true">
        <template #article-action="{ article }">
          <il-shop-article-basket-actions :value="article" @delete="deleteArticle"></il-shop-article-basket-actions>
        </template>
      </il-shop-article-list>
    </div>
    <div class="shoppingBasketCosts">
      <il-shop-shopping-basket-costs to="login" label-back="shop.shoppingBasket.continueShopping"></il-shop-shopping-basket-costs>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import IlShopArticleBasketActions from '@/src/components/shop/il-shop-article-basket-actions.vue';
  import IlShopShoppingBasketCosts from '@/src/components/shop/il-shop-shopping-basket-costs.vue';
  import shoppingBasket from '../../../../utilities/shoppingBasket';
  import ArticleModel from '../../../../services/v2/model/article-model';
  import IlShopArticleList from '../../../../components/shop/il-shop-article-list.vue';

  export default Vue.extend({
    components: {
      IlShopArticleList,
      IlShopArticleBasketActions,
      IlShopShoppingBasketCosts,
    },
    layout: 'layout-widget-shop-reduced',
    data: () => ({
      errorResponse: undefined,
      articles: [] as ArticleModel[],
    }),
    mounted() {
      this.$routerUtils.updateQueryParams({ page: 'shopping-basket' }, { preserve: true });
      this.populateData();
    },
    methods: {
      async populateData() {
        this.errorResponse = undefined;
        this.articles = shoppingBasket.getLocalBasket().basketItems ?? [];
        try {
          await shoppingBasket.updateBasketFromServer();
          const { basketItems } = shoppingBasket.getLocalBasket();
          const articles = basketItems?.map((item) => item.article!) ?? [];
          this.articles = articles;
        } catch (error: any) {
          this.errorResponse = error;
          this.$log.error(error);
        }
      },
      deleteArticle(article: any) {
        const index = this.articles.findIndex((articleInBasket: any) => articleInBasket.id === article.id);
        if (index >= 0) {
          this.articles.splice(index, 1);
        }
      },
    },
  });
</script>

// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ShippingInfoModel from '../../model/shipping-info-model';
import ShippingInfoDTO from '../dto/shipping-info-dto';
import dtoSchema from '../schemas/shipping-info-schema';

export default abstract class ShippingInfoModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ShippingInfoDTO>) {
    super();
    if (data) {
      this.shippingAmount = data.shippingAmount;
      this.vatRate = data.vatRate;
      this.vatAmount = data.vatAmount;
      this.shippingMethod = data.shippingMethod ?? undefined;
      this.isShippingAmountCalculated = data.isShippingAmountCalculated;
    }
  }
  /**
  * @type {double}
  */
  shippingAmount?: number;
  /**
  * @type {double}
  */
  vatRate?: number;
  /**
  * @readonly
  * @type {double}
  */
  vatAmount?: number;
  /**
  */
  shippingMethod?: string;
  /**
  */
  isShippingAmountCalculated?: boolean;

  static toModel(dto: DeepPartial<ShippingInfoDTO>): ShippingInfoModel;
  static toModel(dto: DeepPartial<ShippingInfoDTO> | undefined | null): ShippingInfoModel | undefined;
  static toModel(dto: DeepPartial<ShippingInfoDTO> | undefined | null): ShippingInfoModel | undefined {
    return dto ? new ShippingInfoModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ShippingInfoModel> | ShippingInfoModel): ShippingInfoDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      shippingAmount: unwrapped.shippingAmount,
      vatRate: unwrapped.vatRate,
      vatAmount: unwrapped.vatAmount,
      shippingMethod: unwrapped.shippingMethod,
      isShippingAmountCalculated: unwrapped.isShippingAmountCalculated,
    } as ShippingInfoDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ShippingInfoModel, can not map to ShippingInfoDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}

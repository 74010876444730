<template>
  <p-row class="ilShopArticleBasketActions" align-center>
    <p-number-field
      v-model="count"
      solo
      class="articleCount"
      :step="1"
      :min="0"
      @input="setCount(value, $event)"
    ></p-number-field>
    <p-button class="removeArticle" @click.prevent="removeArticle(value)">
      <p-icon>
        delete
      </p-icon>
    </p-button>
  </p-row>
</template>

<script lang="ts">
  import Vue from 'vue';
  import ArticleModel from '@/src/services/v2/model/article-model';
  import shoppingBasket from '../../utilities/shoppingBasket';

  export default Vue.extend({
    name: 'IlShopArticleBasketActions',
    props: {
      value: { type: Object, default: undefined },
    },
    data: () => ({
      count: 0,
    }),
    mounted() {
      this.refreshCount();
    },
    methods: {
      refreshCount() {
        this.count = shoppingBasket.getCount(this.value);
      },
      setCount(article: ArticleModel, count: number) {
        shoppingBasket.setCount(article, count);
        this.refreshCount();
        this.$emit('setCount', article);
      },
      removeArticle(article: ArticleModel) {
        shoppingBasket.delete(article);
        this.refreshCount();
        this.$emit('delete', article);
      },
    },
  });
</script>

<template>
  <div class="p-img ilShopArticleImage" @click="onClick" @keyup.enter="onClick">
    <p-img
      contain
      :width="width"
      :height="height"
      :src="srcUnwrapped"
    >
      <template #loading>
        <p-row class="loadingIndicator" fill-height align-center justify-center>
          <p-progress-linear indeterminate></p-progress-linear>
        </p-row>
      </template>
      <template #error>
        <p-img
          class="placeholderArticleImg"
          contain
          :width="width"
          :height="height"
          :src="createdPicture"
        ></p-img>
      </template>
    </p-img>
  </div>
</template>

<script lang="ts">
  import { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
  import _ from '@glittr/frontend-core/src/utils';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'IlShopArticleImage',
    props: {
      src: { type: [String, Function, Object, Promise], default: undefined },
      title: { type: String, default: undefined },
      width: { type: [Number, String], default: undefined },
      height: { type: [Number, String], default: undefined },
    },
    data: () => ({
      srcUnwrapped: undefined as any,
      imageLoadError: false,
      createdPicture: undefined as string | undefined,
    }),
    watch: {
      src: {
        immediate: true,
        async handler() {
          if (_.isPromise(this.src)) {
            const res = await this.src;
            this.srcUnwrapped = unwrapDataRecord(res);
          } else {
            this.srcUnwrapped = this.src;
          }
        },
      },
      title: {
        immediate: true,
        handler() {
          this.createdPicture = this.createPicture();
        },
      },
    },
    methods: {
      onClick(event: Event) {
        this.$emit('click', event);
      },
      getHashNumberFromText(text: string) {
        let hash = 0;
        let i;
        let chr;
        if (text.length === 0) return hash;
        for (i = 0; i < text.length; i += 1) {
          chr = text.charCodeAt(i);
          // eslint-disable-next-line no-bitwise
          hash = ((hash << 5) - hash) + chr;
          // eslint-disable-next-line no-bitwise
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
      },
      random(seed: number) {
        const x = Math.sin(seed) * 10000;
        return x - Math.floor(x);
      },
      createPicture() {
        let canvas = document.getElementById('articleTextImageCanvas') as HTMLCanvasElement;
        if (!canvas) {
          canvas = document.createElement('canvas');
          canvas.style.display = 'none';
          canvas.id = 'articleTextImageCanvas';
        }
        const tCtx = canvas.getContext('2d')!;
        tCtx.canvas.width = 400.0;
        tCtx.canvas.height = 566.0;
        tCtx.font = `${tCtx.canvas.width}px Verdana`;

        // Create background gradient
        let gradient = tCtx.createLinearGradient(33, 0, 167, 200);
        gradient.addColorStop(0, 'rgba(240,245,250,1)');
        gradient.addColorStop(1, 'rgba(240,240,240,1)');
        // Fill with gradient
        tCtx.fillStyle = gradient;
        tCtx.fillRect(0, 0, tCtx.canvas.width, tCtx.canvas.height);

        const articleTitle = _.defaultTo(this.title, '-');
        const colors = ['#00a7e6', '#ea8d00', '#6ca123', '#e30613'];
        const hashNumber = this.getHashNumberFromText(articleTitle);
        let firstColorIndex = Math.round(this.random(hashNumber) * (colors.length - 1));
        const secondColorIndex = Math.round(this.random(hashNumber + 7) * (colors.length - 1));
        if (firstColorIndex === secondColorIndex) {
          firstColorIndex = (firstColorIndex + 1) % colors.length;
        }
        const firstColor = colors[firstColorIndex];
        const secondColor = colors[secondColorIndex];
        // Create text gradient
        gradient = tCtx.createLinearGradient(0, 0, tCtx.canvas.width, 0);
        gradient.addColorStop(0, firstColor);
        gradient.addColorStop(1.0, secondColor);

        // Fill with gradient
        tCtx.fillStyle = gradient;
        tCtx.textAlign = 'center';
        tCtx.textBaseline = 'middle';
        tCtx.fillText(articleTitle[0].toUpperCase(), tCtx.canvas.width / 2, tCtx.canvas.height / 2, tCtx.canvas.width);

        const imageSrc = tCtx.canvas.toDataURL();
        return imageSrc;
      },
    },
  });
</script>
